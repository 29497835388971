.all-products {
    background-color: #f5f6f7;
    padding: 70px 0;
    .product-box {
        background-color: #fff;
        height: 100%;
        &:hover {
            box-shadow: -2px 1px 15px 3px rgba(6, 8, 8, 0.06);
            border: 1px solid transparent;
        }
    }
    h1 {
        margin-top: 0px;
    }
}

.testimonials.back-silver {
    background-color: #f5f6f7;
}

.product-page-box {
    .title {
        h2 {
            font-weight: 400;
        }
    }
    .button {
        display: none !important;
    }
    p {
        margin-top: 10px !important;
    }
}