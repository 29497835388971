// #6d6f71

.back-silver {
    background-color: #f5f6f7;
}

.breadcrumbs {
    padding: 5px 0;

    .col-xs-12.col-sm-6 {
        padding-bottom: 0px;
    }

    @include responsive('xs') {
        .col-xs-12.col-sm-6.text-right {
            display: none;
        }
    }

    #breadcrumbs {
        padding: 0px;
    }

    ul {
        list-style: none;

        li {
            display: inline-block;
            font-family: 'Gilroy';
            font-size: 12px;
            font-weight: 300;
            color: #6d6f71;

            strong {
                font-weight: 300;
            }

            a,
            a:visited {
                text-decoration: none;
                color: #6d6f71;
                padding-bottom: 1px;
                border-bottom: 1px solid transparent;
                transition: ease all .3s;

                &:hover {
                    border-bottom: 1px solid #6d6f71;
                }
            }

            &:not(:last-child) {
                margin-right: 6px;
            }
        }
    }

    .goBack,
    .goBack:visited {
        font-family: 'Gilroy';
        font-size: 12px;
        font-weight: 300;
        color: #6d6f71;
        text-decoration: none;
        padding-bottom: 1px;
        border-bottom: 1px solid transparent;
        transition: ease all .3s;

        &:hover {
            border-bottom: 1px solid #6d6f71;
        }
    }
}

.test-content {

    @include responsive('sm') {
        .col-xs-12.col-sm-12.col-md-9 {
            padding-top: 5px;
        }
    }

    .your-data,
    .test-search {
        padding: 30px 20px;
        background-color: #fff;
        margin-bottom: 20px;

        h2 {
            font-size: 24px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            color: $red;
            margin-bottom: 26px;

            @include responsive('md') {
                font-size: 18px;
            }
        }
    }

    .test-search {
        @include responsive('sm') {
            margin-bottom: 0px;
        }
    }

    .test-content {
        padding: 40px 30px;
        background-color: #fff;

        @include responsive('xs') {
            padding: 40px 0 40px 20px;
        }

        h1 {
            font-family: 'Gilroy';
            font-size: 24px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            color: $red;
        }

        span {
            font-family: 'Gilroy';
            font-size: 16px;
            font-weight: 400;
            display: block;
            margin-bottom: 10px;
        }

        p {
            color: #6d6f71;
            font-weight: 400;
            font-size: 16px;
            line-height: 150%;

            &:not(:last-child) {
                margin-bottom: 20px;
            }
        }

        .download {
            position: relative;

            a {
                color: $red;
                font-size: 18px;
                font-weight: 600;
                line-height: 19.65px;
                text-decoration: none;
                transition: ease all .3s;

                i {
                    font-size: 31px;
                    position: relative;
                    top: 8px;
                }

                &:hover {
                    color: lighten($red, 10%);
                }
            }
        }

        .main-data {
            table {
                font-family: 'Gilroy';
                border-collapse: collapse;
                width: 100%;
                margin-top: 40px;
                border: 1px solid #ced9d8;

                td,
                th {
                    padding: 16px;
                }

                tr:nth-child(even){
                    background-color: #fff;
                }

                th {
                    text-align: left;
                    background-color: #eef3f3;
                    color: #878a8b;
                    padding-top: 20px;
                    border: 1px solid #ced9d8;

                    &:not(:first-child) {
                        text-align: center;
                    }
                }

                td {
                    color: #6d6f71;
                    font-weight: 400;
                    font-size: 16px;
                    border-left: 1px solid #ced9d8;

                    &:not(:first-child) {
                        text-align: center;
                    }
                }
            }
        }
    }

    .your-data {
        ul {
            list-style: none;

            li {
                font-family: 'Gilroy';
                font-size: 14px;
                font-weight: 400;
                line-height: 19.65px;
                color: #6d6f71;

                &:not(:last-child) {
                    margin-bottom: 4px;
                }
            }
        }
    }

    .test-search {
        position: relative;

        .search-again {
            position: relative;

            input {
                color: #000000;
                font-family: 'Gilroy';
                font-size: 16px;
                font-weight: 300;
                line-height: 19.65px;
                padding: 12px 15px;
                border: 1px solid #e4ecea;
                border-radius: 0px;
                transition: ease all .3s;
                width: 100%;

                @include responsive('md') {
                    font-size: 12px;
                }
            }
    
            span {
                position: absolute;
                top: 0;
                right: 0;
                width: 49px;
                height: 45px;
                border-left: 1px solid #dae3e2;
                z-index: 9;

                @include responsive('md') {
                    width: 40px;
                }

                i {
                    font-size: 25px;
                    position: absolute;
                    top: 50%;
                    left: 50%;  
                    transform: translate(-55%, -40%);
                    color: #5b7073;

                    @include responsive('md') {
                        font-size: 18px;
                    }
                }
            }
        }

        a,
        .active-search {
            font-family: 'Gilroy';
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            padding: 14px 30px 10px;
            display: inline-block;
            border-radius: 20px;
            background-color: $blue;
            color: #fff;
            text-decoration: none;
            margin-top: 30px;
            transition: ease all .3s;
            border: 0px;
            cursor: pointer;

            @include responsive('md') {
                font-size: 12px;
                padding: 14px 18px 10px;
            }

            &:hover {
                background-color: lighten($blue, 5%);
            }
        }
    }
}

.back {
    margin-top: 20px;
    a,
    a:visited {
        font-family: 'Gilroy';
        font-size: 16px;
        font-weight: 400;
        line-height: 19.65px;
        color: $red;
        text-decoration: none;
        transition: ease all .3s;

        &:hover {
            color: lighten($red, 5%);
        }
    }
}

.main-data {
    overflow: auto;
}