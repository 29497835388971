// @import url('https://fonts.googleapis.com/css?family=Josefin+Sans:300,400,600,700&display=swap&subset=latin-ext');

@font-face {
    font-family: 'Gilroy';
    src: url('../includes/src/fonts/Gilroy-Regular.eot');
    src: local('Gilroy Regular'), local('Gilroy-Regular'),
        url('../includes/src/fonts/Gilroy-Regular.eot?#iefix') format('embedded-opentype'),
        url('../includes/src/fonts/Gilroy-Regular.woff2') format('woff2'),
        url('../includes/src/fonts/Gilroy-Regular.woff') format('woff'),
        url('../includes/src/fonts/Gilroy-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../includes/src/fonts/Gilroy-ExtraboldItalic.eot');
    src: local('Gilroy Extrabold Italic'), local('Gilroy-ExtraboldItalic'),
        url('../includes/src/fonts/Gilroy-ExtraboldItalic.eot?#iefix') format('embedded-opentype'),
        url('../includes/src/fonts/Gilroy-ExtraboldItalic.woff2') format('woff2'),
        url('../includes/src/fonts/Gilroy-ExtraboldItalic.woff') format('woff'),
        url('../includes/src/fonts/Gilroy-ExtraboldItalic.ttf') format('truetype');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../includes/src/fonts/Gilroy-Bold.eot');
    src: local('Gilroy Bold'), local('Gilroy-Bold'),
        url('../includes/src/fonts/Gilroy-Bold.eot?#iefix') format('embedded-opentype'),
        url('../includes/src/fonts/Gilroy-Bold.woff2') format('woff2'),
        url('../includes/src/fonts/Gilroy-Bold.woff') format('woff'),
        url('../includes/src/fonts/Gilroy-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../includes/src/fonts/Gilroy-Black.eot');
    src: local('Gilroy Black'), local('Gilroy-Black'),
        url('../includes/src/fonts/Gilroy-Black.eot?#iefix') format('embedded-opentype'),
        url('../includes/src/fonts/Gilroy-Black.woff2') format('woff2'),
        url('../includes/src/fonts/Gilroy-Black.woff') format('woff'),
        url('../includes/src/fonts/Gilroy-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../includes/src/fonts/Gilroy-Light.eot');
    src: local('Gilroy Light'), local('Gilroy-Light'),
        url('../includes/src/fonts/Gilroy-Light.eot?#iefix') format('embedded-opentype'),
        url('../includes/src/fonts/Gilroy-Light.woff2') format('woff2'),
        url('../includes/src/fonts/Gilroy-Light.woff') format('woff'),
        url('../includes/src/fonts/Gilroy-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../includes/src/fonts/Gilroy-Semibold.eot');
    src: local('Gilroy Semibold'), local('Gilroy-Semibold'),
        url('../includes/src/fonts/Gilroy-Semibold.eot?#iefix') format('embedded-opentype'),
        url('../includes/src/fonts/Gilroy-Semibold.woff2') format('woff2'),
        url('../includes/src/fonts/Gilroy-Semibold.woff') format('woff'),
        url('../includes/src/fonts/Gilroy-Semibold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../includes/src/fonts/Gilroy-Medium.eot');
    src: local('Gilroy Medium'), local('Gilroy-Medium'),
        url('../includes/src/fonts/Gilroy-Medium.eot?#iefix') format('embedded-opentype'),
        url('../includes/src/fonts/Gilroy-Medium.woff2') format('woff2'),
        url('../includes/src/fonts/Gilroy-Medium.woff') format('woff'),
        url('../includes/src/fonts/Gilroy-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../includes/src/fonts/Gilroy-MediumItalic.eot');
    src: local('Gilroy Medium Italic'), local('Gilroy-MediumItalic'),
        url('../includes/src/fonts/Gilroy-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('../includes/src/fonts/Gilroy-MediumItalic.woff2') format('woff2'),
        url('../includes/src/fonts/Gilroy-MediumItalic.woff') format('woff'),
        url('../includes/src/fonts/Gilroy-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../includes/src/fonts/Gilroy-BlackItalic.eot');
    src: local('Gilroy Black Italic'), local('Gilroy-BlackItalic'),
        url('../includes/src/fonts/Gilroy-BlackItalic.eot?#iefix') format('embedded-opentype'),
        url('../includes/src/fonts/Gilroy-BlackItalic.woff2') format('woff2'),
        url('../includes/src/fonts/Gilroy-BlackItalic.woff') format('woff'),
        url('../includes/src/fonts/Gilroy-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../includes/src/fonts/Gilroy-RegularItalic.eot');
    src: local('Gilroy Regular Italic'), local('Gilroy-RegularItalic'),
        url('../includes/src/fonts/Gilroy-RegularItalic.eot?#iefix') format('embedded-opentype'),
        url('../includes/src/fonts/Gilroy-RegularItalic.woff2') format('woff2'),
        url('../includes/src/fonts/Gilroy-RegularItalic.woff') format('woff'),
        url('../includes/src/fonts/Gilroy-RegularItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../includes/src/fonts/Gilroy-SemiboldItalic.eot');
    src: local('Gilroy Semibold Italic'), local('Gilroy-SemiboldItalic'),
        url('../includes/src/fonts/Gilroy-SemiboldItalic.eot?#iefix') format('embedded-opentype'),
        url('../includes/src/fonts/Gilroy-SemiboldItalic.woff2') format('woff2'),
        url('../includes/src/fonts/Gilroy-SemiboldItalic.woff') format('woff'),
        url('../includes/src/fonts/Gilroy-SemiboldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../includes/src/fonts/Gilroy-Extrabold.eot');
    src: local('Gilroy Extrabold'), local('Gilroy-Extrabold'),
        url('../includes/src/fonts/Gilroy-Extrabold.eot?#iefix') format('embedded-opentype'),
        url('../includes/src/fonts/Gilroy-Extrabold.woff2') format('woff2'),
        url('../includes/src/fonts/Gilroy-Extrabold.woff') format('woff'),
        url('../includes/src/fonts/Gilroy-Extrabold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../includes/src/fonts/Gilroy-BoldItalic.eot');
    src: local('Gilroy Bold Italic'), local('Gilroy-BoldItalic'),
        url('../includes/src/fonts/Gilroy-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('../includes/src/fonts/Gilroy-BoldItalic.woff2') format('woff2'),
        url('../includes/src/fonts/Gilroy-BoldItalic.woff') format('woff'),
        url('../includes/src/fonts/Gilroy-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../includes/src/fonts/Gilroy-LightItalic.eot');
    src: local('Gilroy Light Italic'), local('Gilroy-LightItalic'),
        url('../includes/src/fonts/Gilroy-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('../includes/src/fonts/Gilroy-LightItalic.woff2') format('woff2'),
        url('../includes/src/fonts/Gilroy-LightItalic.woff') format('woff'),
        url('../includes/src/fonts/Gilroy-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}