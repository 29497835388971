#nav-icon3 {
    width: 35px;
    height: 20px;
    transform: rotate(0);
    transition: .5s ease-in-out;
    cursor: pointer;
    position: fixed;
    top: 30px;
    right: 30px;
    display: none;
    z-index: 9999999999;
    display: none;

    @include responsive('sm') {
        display: block;
    }

    span {
        display: block;
        position: absolute;
        height: 2px;
        width: 100%;
        background: #3f4c57;
        border-radius: 9px;
        opacity: 1;
        left: 0;
        transform: rotate(0);
        transition: .25s ease-in-out;

        &:nth-child(1) {
            top: 0;
        }

        &:nth-child(2),
        &:nth-child(3) {
            top: 10px;
        }

        &:nth-child(4) {
            top: 20px;
        }
    }

    &.open {
        span {
            &:nth-child(1),
            &:nth-child(4) {
                top: 18px;
                width: 0%;
                left: 50%;
            }
    
            &:nth-child(2) {
                transform: rotate(45deg);
            }

            &:nth-child(3) {
                transform: rotate(-45deg);
            }
        }
    }
}

.mob-nav {
    position: fixed;
    top: 0;
    right: 0;
    width: 300px;
    height: 100%;
    min-height: 100%;
    background-color: #fff;
    z-index: 9;
    padding-top: 100px;
    transition: ease all .4s;
    opacity: 0;
    visibility: hidden;
    display: none;
    box-shadow: -15px 0 12px 1px rgba(0, 0, 0, 0.04);
    overflow-y: auto;

    &.active {
        z-index: 99999;
    }

    @include responsive('sm') {
        display: block;
    }
    .lang-dropdown-mob {
        display: flex;
        position: absolute;
        top: 32px;
        .lang-item {
            height: 45px;
            width: 45px;
            border-bottom: unset;
            padding-bottom: 0;
            .language {
                color: $black !important;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 20px;
                text-transform: uppercase;
                text-decoration: none;
            }
        }
    }

    ul {
        list-style: none;
        padding-left: 0;
        margin: 0 20px;
        width: calc(100% - 40px);
        li {
            text-align: left;
            margin-bottom: 15px;
            border-bottom: 1px solid #eef3f3;
            padding-bottom: 10px;

            a {
                color: #666767;
                text-decoration: none;
                transition: ease all .3s;
                position: relative;
                font-size: 14px;
                font-weight: 400;
                text-transform: uppercase;
            }
        }
    }

    &.active {
        opacity: 1;
        visibility: visible;
    }

    .navigation-mobile {
        margin-top: 40px;
        list-style: none;

        li {
            display: block;
            margin-bottom: 20px;
            
            a,
            a:visited {
                color: #bababa;
                font-family: "Poppins";
                font-size: 18px;
                font-weight: 600;
                text-decoration: none;
                transition: ease all .3s;
            }
        }
    }

    .contact-information {
        margin-top: 10px;
        background-color: #e6edec;
        padding: 20px;

        .phone {
            a {
                font-weight: 400;
                font-size: 30px;
                color: #222222;
                text-decoration: none;
                display: inline-block;
                margin-bottom: 2px;
                margin-top: 4px;
            }
            
        }

        .email {
            a {
                font-size: 14px;
                font-weight: 400;
                color: #94999a;
                line-height: 24.04px;
                text-decoration: none;
            }
        }
    }

    .form-input {
        padding: 20px;
        position: relative;

        input {
            display: block;
            width: 100%;
            border: 1px solid #e4ecea;
            font-size: 14px;
            font-weight: 300;
            padding: 10px 10px;
            border-radius: 0px;
            height: 38px;
        }

        button {
            position: absolute;
            top: 22px;
            right: 30px;
            border-radius: 0;
            border: 0px;
            height: 38px;
            background-color: transparent;
            i {
                font-size: 18px;
            }
        }
    }
}