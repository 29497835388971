#customer_login {
    padding: 15px;

    .u-column1,
    .u-column2 {
        display: block;
        margin-bottom: 20px;
        border: 1px solid #eeeeee;
        padding: 20px;

        span {
            display: inline-block !important;
        }

        input {
            display: block;
            padding: 10px;
            border-radius: 0px;
            margin-top: -10px;
            width: 100%;
            border-width: 1px;
        }

        h2 {
            margin-bottom: 15px;
        }
    }

    button {
        display: inline-block;
        padding: 12px 30px;
        border: 0px;
        border-radius: 20px;
        background-color: $red;
        color: #fff;
        cursor: pointer;
        transition: ease all .3s;
        width: max-content;
        &:hover {
            background-color: lighten($red, 5%);
        }
    }

    .woocommerce-privacy-policy-text {
        margin-bottom: 15px;
    }

    .lost_password {
        a,
        a:visited {
            color: #6d6f71;
        }
    }
}

.woocommerce-form__label.woocommerce-form__label-for-checkbox.woocommerce-form-login__rememberme {
    display: none;
}

