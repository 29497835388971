@font-face {
    font-family: 'dbs';
    font-display: swap;
    src: url('../assets/fonts/dbs.eot');
    src: url('../assets/fonts/dbs.eot?#iefix') format('embedded-opentype'),
         url('../assets/fonts/dbs.woff') format('woff'),
         url('../assets/fonts/dbs.ttf') format('truetype'),
         url('../assets/fonts/dbs.svg#dbs') format('svg');
    font-weight: normal;
    font-style: normal;
}
[class*='dbs-']:before{
	display: inline-block;
   font-family: 'dbs';
   font-style: normal;
   font-weight: normal;
   line-height: 1;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
}
.dbs-plus:before{content:'\0041';}
.dbs-search:before{content:'\0042';}
.dbs-bag:before{content:'\0043';}
.dbs-close:before{content:'\0044';}
.dbs-email:before{content:'\0045';}
.dbs-minus:before{content:'\0046';}
.dbs-pdf:before{content:'\0047';}
.dbs-back:before{content:'\0048';}