.first-revel {
    background-color: #f5f6f7;
    height: calc(100vh - 108px - 31px - 90px);
    min-height: 500px;
    position: relative;

    .hero-wrapper {
        max-width: 574px;
        margin: 0 auto;
        position: absolute;
        top: 50%;
        left: 50%;  
        transform: translate(-50%, -50%);

        @include responsive('xs') {
            max-width: 100%;
            width: 100%;
            padding: 0 30px;
        }

        h1 {
            color: $red;
            line-height: 19.65px;
            font-size: 24px;
            font-weight: 700;
            text-transform: uppercase;
        }

        p {
            color: #76787a;
            font-family: 'Gilroy';
            font-size: 14px;
            font-weight: 400;
            line-height: 150%;
            margin-top: 15px;
            display: block;
        }
    }

    .search-results {
        width: 460px;
        margin: 0 auto;
        margin-top: 60px;
        position: relative;
        overflow: hidden;

        @include responsive('xs') {
            width: 100%;
        }

        input {
            display: block;
            width: 100%;
        }

        input[type="text"] {
            color: #000000;
            font-family: 'Gilroy';
            font-size: 14px;
            font-weight: 300;
            line-height: 19.65px;
            padding: 12px 15px;
            border: 1px solid #e4ecea;
            border-radius: 0px;
            transition: ease all .3s;

            @include responsive('ss') {
                font-size: 12px;
            }

            &:focus {
                border: 1px solid $red;
            }
        }

        input[type="submit"] {
            position: absolute;
            top: 0;
            right: 0;
            width: auto;
            color: #fff;
            font-family: 'Gilroy';
            font-size: 14px;
            font-weight: 400;
            text-transform: uppercase;
            background-color: $red;
            border: 0px;
            border-radius: 0px;
            padding: 18px 15px;
            cursor: pointer;
            transition: ease all .3s;

            @include responsive('ss') {
                font-size: 12px;
            }

            &:hover {
                background-color: lighten($red, 5%);
            }

            @include responsive('ss') {
                position: relative;
                display: block;
                width: 100%;
                border: 0px;
            }
        }
    }
}

.liner {
    display: block;
    height: 31px;
    background-color: #ffffff;
}

.home_404 {
    h1,
    p {
        color: $red;
    }

    a,
    a:visited,
    a:active,
    a:focus {
        text-decoration: none;
        color: #6d6f71;
        text-decoration: none;
        padding-bottom: 1px;
        border-bottom: 1px solid transparent;
        -webkit-transition: ease all .3s;
        transition: ease all .3s;

        &:hover {
            color: #6d6f71;
            border-bottom: 1px solid #6d6f71;
        }
    }
}

.hero {
    padding: 20px;
    background-color: #f5f6f7;

    h2 {
        color: $red;
        font-size: 36px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-bottom: 20px;

        @include responsive('md') {
            font-size: 28px;
        }

        @include responsive('sm') {
            font-size: 24px;
        }

        @include responsive('xs') {
            text-align: center;
        }
    }

    p {
        font-family: 'Gilroy';
        font-size: 18px;
        font-weight: 400;
        color: #8f9193;
        line-height: 30px;
        margin-bottom: 30px;

        @include responsive('xs') {
            text-align: center;
        }
    }

    img {
        width: 100%;
    }

    @include responsive('xs') {
       .col-xs-12.col-sm-6 {
           text-align: center;
       }
    }
}

.btn,
.btn:visited,
.btn:active,
.btn:focus {
    display: inline-block;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-decoration: none;
    color: #fff;
    padding: 14px 30px 12px;
    background-color: $blue;
    border-radius: 20px;
    transition: ease all .4s;
    transform: perspective(1px);
    backface-visibility: hidden;

    &:hover {
        background-color: lighten($blue, 5%);
        color: #fff;
        box-shadow: 0 8px 24px 0 rgba(68,99,137,.1);
        backface-visibility: hidden;
    }
}

.heading-title {
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: $black;
    text-align: center;
    margin-bottom: 10px;
}

.heading-text {
    color: $grey;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    max-width: 600px;
    text-align: center;
    margin: 0 auto;
    margin-bottom: 30px;
}

.products-section {
    padding: 65px 0;

    @include responsive('sm') {
        padding: 40px;
    }
}

.p-r {
    position: relative;
}

.product-box {
    border: 1px solid transparent;
    transition: ease all .3s;

    &:hover {
        border: 1px solid #e4ecea;
        box-shadow: 0 8px 24px 0 rgba(68,99,137,.05);
    }

    .price_small {
        display: none;
    }

    a,
    a:visited {
        display: block;
        width: 100%;
        text-decoration: none;
        padding: 15px;
    }

    img {
        width: 100%;
        display: block;
        height: 182px;
        object-fit: contain;
        margin-bottom: 26px;
    }

    .title {
        color: #313131;
        font-size: 18px;
        font-weight: 400;
        margin-bottom: 10px;

        h2 {
            font-size: 18px;
        }
    }

    .price {
        margin-bottom: 10px;

        span {
            font-size: 18px;
            font-weight: 600;
            line-height: 30px;
            color: $red;
        }
    }
    
    p {
        font-size: 16px;
        font-weight: 300;
        line-height: 150%;
        color: #a1a2a4;
        margin-bottom: 15px;
    }

    span {
        color: #a1a2a4;
        font-weight: 400;
    }
}

.text-block {
    padding: 80px 0;
    background-color: #f5f6f7;

    @include responsive('xs') {
        padding: 40px 0;
    }

    h2 {
        color: $red;
        font-size: 24px;
        font-weight: 400;
        line-height: 19.65px;
        padding-bottom: 20px;
        margin-bottom: 10px;

        @include responsive('xs') {
            text-align: center;
        }

        &::after {
            content: '';
            display: block;
            width: 64px;
            height: 1px;
            background-color: #5b7073;
            margin-top: 20px;

            @include responsive('xs') {
                margin: 20px auto 0;
            }
        }
    }

    p {
        font-size: 18px;
        font-weight: 400;
        line-height: 30px;
        color: $grey;

        @include responsive('xs') {
            text-align: center;
        }
    }

    .row:first-child {
        margin-bottom: 40px;
    }

    @include responsive('xs') {
        .col-xs-12.col-sm-8 {
            text-align: center;
        }
    }

    a,
    a:visited {
        font-size: 14px;
        font-weight: 600;
        color: $red;
        text-decoration: none;
        margin-top: 20px;
        display: inline-block;
        padding-bottom: 1px;
        border-bottom: 1px solid transparent;
        transition: ease all .3s;

        &:hover {
            color: $red;
            border-bottom: 1px solid $red;
        }
    }
}

.testimonials {
    padding: 70px 0;
    background-color: #fff;

    @include responsive('sm') {
        padding: 40px;
    }

    h2 {
        margin-bottom: 50px;
    }

    .lSNext {
        &::after {
            color: #4d4d4b !important;
        }
    }

    .lSPrev {
        &::after {
            color: #4d4d4b !important;
        }
    }

    .name {
        font-size: 18px;
        font-weight: 600;
        line-height: 27.97px;
        color: $red;

        @include responsive('xs') {
            text-align: center;
        }
    }

    .comment {
        font-size: 16px;
        font-weight: 300;
        line-height: 170%;
        margin-top: 15px;
        color: #a1a2a4;

        @include responsive('xs') {
            text-align: center;
        }
    }

    .lSSlideOuter {
        padding: 0 1rem;
    }
}

@include responsive('sm') {
    .lSAction > a.lSPrev {
        left: -30px;
    }
}

@include responsive('sm') {
    .lSAction > a.lSNext {
        right: -30px;
    }
}
