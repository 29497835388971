@import url('https://fonts.googleapis.com/css?family=Nunito:300,400,600');

.acf-postbox {
    font-family: 'Nunito', sans-serif;
    border: 1px solid #eee !important;
    border-radius: 5px !important;
    overflow: hidden;
    box-shadow: none;

    h2.ui-sortable-handle {
        background-color: #22A7F0;
        color: #fff;
        padding: 15px !important;
        font-size: 16px !important;
        text-transform: uppercase;
        border-bottom: 1px solid #22A7F0;

        span {
            color: #fff;
        }

        .acf-hndle-cog {
            color: #fff;
        }
    }

    .handlediv {
        width: 46px;
        height: 53px;

        .toggle-indicator {
            color: #fff;
        }
    }

    .acf-fields.-sidebar.-left {
        &::before {
            border: 0 !important; 
            background-color: #2C3E50;
        }
    }

    .acf-fields {
        .acf-tab-wrap.-left {
            
            &::before {
                border: 0px;
            }

            .acf-tab-group {
                li .acf-tab-button {
                    padding: 14px;
                    background-color: #2C3E50;
                    color: #fff;
                    border: 0;
                }

                li.active a {
                    color: #2C3E50;
                    background-color: #fff !important;
                }
            }
        }
    }

    .acf-field {
        &::before {
            background-color: #fff !important;
        }
    }

    .acf-field input[type="text"], 
    .acf-field input[type="password"], 
    .acf-field input[type="number"], 
    .acf-field input[type="search"], 
    .acf-field input[type="email"], 
    .acf-field input[type="url"], 
    .acf-field textarea, 
    .acf-field select {
        padding: 7px 10px;
        box-shadow: none;
        border-radius: 4px;
        font-size: 12px;
        color: #2C3E50;
        height: auto !important;
    }

    .acf-input-wrap input,
    .acf-input-wrap select {
        height: auto !important;
    }

    .acf-repeater.-table {
        .acf-table {

            border-radius: 5px;
            overflow: hidden;

            thead {
                th {
                    background-color: #2C3E50;
                    color: #fff;
                    border: 0;
                    padding: 14px;
                }
            }

            tr.acf-row {
                .acf-row-handle {
                    span {
                        font-size: 18px;
                        color: #2C3E50;
                    }
                }
            }
        }

        .acf-button.button.button-primary {
            background-color: #2C3E50;
            color: #fff;
            box-shadow: none;
            text-shadow: none;
        }
    }
}

// WORDPRESS ADMIN HIDE
// #toplevel_page_smush {
//     display: none;
// }