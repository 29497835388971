.simple-page {
    .test-content .woocommerce-MyAccount-navigation {
        display: none;
    }
}

.account-menu {
    padding: 30px 20px;
    background-color: #fff;
    margin-bottom: 20px;

    h2 {
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        color: $red;
        margin-bottom: 26px;
    }

    ul {
        list-style: none;

        li {
            display: block;

            &:not(:last-child) {
                margin-bottom: 10px;
            }

            a,
            a:visited {
                font-size: 16px;
                font-weight: 400;
                text-decoration: none;
                color: #8f9092;
            }
        }
    }
}

.test-content.content-inside.simple-page.my-account-page {
    .woocommerce {
        padding: 15px;

        .woocommerce-form-row {
            display: flex;
            flex-direction: column;
        }
        p {
            color: #8f9092;
        }
        a,
        a:visited {
            color: #8f9092;
        }

        span {
            display: inline-block;
        }

        input {
            padding: 10px;
            display: block;
            // border-radius: 0px;
            // margin-top: -10px;
        }

        em {
            margin-top: 20px;
            display: block;
        }

        fieldset {
            input {
                margin-top: 5px;
            }
        }

        .edit-account .woocommerce-Button {
            font-size: 16px;
            padding: 14px 30px 10px;
            display: inline-block;
            border-radius: 20px;
            background-color: $red;
            color: #fff;
            margin-top: 30px;
            transition: ease all .3s;
            border: 0;
            cursor: pointer;

            &:hover {
                background-color: lighten($red, 5%);
            }
        }
    }
}

.test-content .test-content .test-account-page {
    position: relative;

    table {
        margin-top: 15px;

        th,td {
            text-align: left !important; 
            transition: ease all .3s;
        }

        tr:hover {
            td {
                background-color: rgba(220, 0, 0, 0.1);
            }
        }

        a {
            text-decoration: none;
            padding-bottom: 1px;
            border-bottom: 1px solid transparent;
            transition: ease all .3s;

            &:hover {
                border-bottom: 1px solid #8f9092;
            }
        }
    }

    .add-test {
        color: #fff !important;
        position: absolute;
        top: -68px;
        right: 0;
    }
}

.middle-page.home_404 {
    a {
        color: #fff !important;
        padding: 14px 30px 10px;
    }
}

.woocommerce-MyAccount-navigation {
    li.is-active {
        a {
            color: rgba(220, 0, 0, 1) !important;
        }
    }
}