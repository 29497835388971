// Grid
$grid-columns: 12 !default;

$xs-max: 767px !default;
$sm-max: 992px !default;
$md-max: 1200px !default;
$lg-max: 1300px !default;

$gutter: 1rem !default;

$content-well-max-width: "none" !default;

$sm-start: ($xs-max + 1);
$md-start: ($sm-max + 1);
$lg-start: ($md-max + 1);
$xl-start: ($lg-max + 1);

// Breakpoints
$breakpoint-xs-only: "only screen and (max-width: #{$xs-max})";
$breakpoint-sm-up: "only screen and (min-width: #{$sm-start})";
$breakpoint-sm-only: "only screen and (min-width: #{$sm-start}) and (max-width: #{$sm-max})"; 
$breakpoint-md-up: "only screen and (min-width: #{$md-start})";
$breakpoint-md-only: "only screen and (min-width: #{$md-start}) and (max-width: #{$md-max})";
$breakpoint-lg-up: "only screen and (min-width: #{$lg-start})";
$breakpoint-lg-only: "only screen and (min-width: #{$lg-start}) and (max-width: #{$lg-max})";
$breakpoint-xl-up: "only screen and (min-width: #{$xl-start})";

$breakpoints-all: ($breakpoint-xs-only, $breakpoint-sm-up, $breakpoint-sm-only, $breakpoint-md-up, $breakpoint-md-only, $breakpoint-lg-up, $breakpoint-lg-only, $breakpoint-xl-up);
$breakpoint-ups: ($breakpoint-sm-up, $breakpoint-md-up, $breakpoint-lg-up, $breakpoint-xl-up);
$breakpoint-all-prefixes: ("xs", "xs-only", "sm", "sm-only", "md", "md-only", "lg", "lg-only", "xl");
$breakpoint-up-prefixes: ("xs", "sm", "md", "lg", "xl");
