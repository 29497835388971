@import '/node_modules/lightslider/dist/css/lightslider.css';


.lSSlideOuter .lSPager.lSpg > li {
    padding: 2px;

    &:hover {
        a {
            background-color: $red;
        }
    }

    a {
        width: 15px;
        height: 15px;
        background-color: rgba(255, 0, 0, 0.26);

        &:hover {
            background-color: $red !important;
        }
    }

    &.active {
        a {
            background-color: $red;
        }
    }
}

.lSSlideOuter .lSPager.lSpg {
    margin-top: 20px !important;
    margin-bottom: 15px;
}

.lSAction {
    z-index: 9999;

    > a {
        &.lSNext {
            background: none;
            right: -40px;

            &::after {
                content:'\0048';
                display: block;
                display: inline-block;
                font-family: 'dbs';
                font-style: normal;
                font-weight: normal;
                line-height: 1;
                transform: rotate(180deg);
                font-size: 22px;
                color: $red;
            }
        }

        &.lSPrev {
            background: none;
            left: -40px;
            
            &::after {
                content:'\0048';
                display: block;
                display: inline-block;
                font-family: 'dbs';
                font-style: normal;
                font-weight: normal;
                line-height: 1;
                font-size: 22px;
                color: $red;
            }
        }
    }
}