// BREAKPOINTS
@mixin responsive($point) {

    @if $point == ss {
        @media (max-width: 480px) {
            @content ;
        }
    }

    @else if $point == xs {
        @media (max-width: 767px) {
            @content ;
        }
    }

    @else if $point == sm {
        @media (max-width: 992px) {
            @content ;
        }
    }

    @else if $point == md {
        @media (max-width: 1200px) {
            @content ;
        }
    }

    @else if $point == lg {
        @media (max-width: 1300px) {
            @content ;
        }
    }

    @else if $point == xlg {
        @media (max-width: 1400px) {
            @content ;
        }
    }

}