footer {
    padding: 20px 0;
    background: linear-gradient(180deg, #43B7E2 0%, #4AC7EB 100%);;
    height: 90px;

    @include responsive('xs') {
        height: auto;
        text-align: center;

        .col-xs-12.col-sm-6.text-right {
            text-align: center;
        }
    }

    p {
        color: $white;
        font-family: 'Gilroy';
        font-size: 14px;
        font-weight: 300;
        line-height: 19.65px;
        transition: ease all .3s;

        a,
        a:visited {
            color: $white;
            text-decoration: none;
            padding-bottom: 1px;
            border-bottom: 1px solid transparent;
            transition: ease all .3s;

            &:hover {
                color: $red;
                border-bottom: 1px solid $red;
            }
        }
    }
}

.footer-menu {
    padding: 0 0 40px;

    &.no-main {
        .row::after {
            display: none;
        }
    }

    .row {
        padding-top: 50px;

        &::after {
            content: '';
            display: block;
            width: calc(100% - 2rem);
            position: absolute;
            top: 0;
            left: 1rem;
            height: 1px;
            background-color: #e4ecea;
        }
    }

    @include responsive('sm') {
        .col-xs-12.col-sm-12 {
            text-align: center;
        }
    }
    
    .btn {
        position: absolute;
        bottom: 8px;
        right: 1rem;  

        @include responsive('sm') {
            position: relative;
            bottom: 0;
            right: auto;
        }
    }

    ul {
        list-style: none;
        margin-left: 0;

        @include responsive('sm') {
            display: none;
        }

        li {
            display: inline-block;

            &:not(:last-child) {
                margin-right: 26px;
            }

            a {
                color: $black;
                font-family: 'Gilroy';
                font-size: 16px;
                font-weight: 400;
                line-height: normal;
                transition: ease all .3s;
                text-decoration: none;
                padding-bottom: 4px;
                border-bottom: 1px solid transparent;

                &:hover {
                    color: #000;
                    border-bottom: 1px solid #e4ecea;
                }
            }
        }
    }
}

.contact-popup,
.test-add-popup {
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(250, 252, 252, 0.8);
    width: 100%;
    height: 100%;
    overflow: auto;
    opacity: 0;
    visibility: hidden;
    transition: ease all .3s;
    z-index: 99999999;

    &.active {
        opacity: 1;
        visibility: visible;
    }

    .popupt-warpper {
        width: 881px;
        box-shadow: -22px 10px 87px 11px rgba(6, 8, 8, 0.05);
        background-color: #ffffff;
        position: absolute;
        top: 50%;
        left: 50%;  
        transform: translate(-50%, -50%);
        padding: 37px;

        @include responsive('sm') {
            width: 100%;
            position: relative;
            top: auto;
            left: auto;
            transform: none;
            overflow: auto;
        }

        .close-popup,
        .close-popup-test {
            position: absolute;
            top: -15px;
            right: -15px;
            width: 30px;
            height: 30px;
            background-color: #15191a;
            border-radius: 100%;
            color: #fff;
            font-size: 32px;
            text-align: center;
            cursor: pointer;
            transition: ease all .3s;

            @include responsive('sm') {
                top: 5px;
                right: 5px;
            }

            span {
                position: absolute;
                top: 50%;
                left: 50%;  
                transform: translate(-50%, -50%);
            }

            &:hover {
                background-color: lighten(#15191a, 15%);
            }
        }

        h2 {
            font-size: 30px;
            font-weight: 400;
            margin-bottom: 15px;
            color: $red;

            @include responsive('sm') {
                font-size: 24px;
            }
        }

        form {
            margin-left: -15px;
            margin-right: -15px;

            input,
            textarea {
                font-size: 16px;
                font-weight: 300;
                border: 0px;
                border-radius: 0;
                border-bottom: 1px solid rgba(206, 217, 216, 0.36);
                display: block;
                width: 100%;
                padding-left: 0;
            }

            textarea {
                padding: 10px;
                border: 1px solid rgba(206, 217, 216, 0.36);
                height: 120px;
            }

            input[type="submit"] {
                display: inline-block;
                width: auto;
                padding: 14px 40px 11px;
                color: #fff;
                font-size: 18px;
                font-weight: 400;
                text-transform: uppercase;
                border-radius: 21px;
                background-color: $red;
                cursor: pointer;
                transition: ease all .3s;

                &:hover {
                    background-color: lighten($red, 5%);
                }
            }

            .checkbox {
                margin-top: 20px;
                position: relative;

                input {
                    position: absolute;
                    top: -1px;
                    left: 0;
                    display: inline-block;
                    width: auto;
                    z-index: 9;
                }

                span {
                    font-size: 12px;
                    font-weight: 300;
                    color: #9d9d9d;
                    margin-left: 5px;

                    &.wpcf7-list-item-label {
                        margin-left: 10px;
                    }

                    a {
                        color: #9d9d9d;
                        text-decoration: none;
                        padding-bottom: 1px;
                        border-bottom: 1px solid transparent;
                        transition: ease all .3s;

                        &:hover {
                            border-bottom: 1px solid #9d9d9d;
                        }
                    }
                }
            }            
        }
    }
}

#submit-add-test {
    .error {
        border-bottom: 1px solid $red;
    }
}