.woocommerce-form-coupon-toggle {
    display: none;
}

.test-content.content-inside.simple-page.checkout-pages {
    h1 {
        margin-bottom: 30px;
    }

    .woocommerce-notices-wrapper {
        margin: 0;
        margin-bottom: 20px;
    }

    table.shop_table {
        border: 0px;

        thead {
            background-color: #f6f9f9;

            th {
                color: #222;
                font-size: 16px;
                font-weight: 400;
            }
        }

        td.product-remove {
            width: 60px;
        }

        td.product-thumbnail {
            width: 200px;
            img {
                width: 161px;
                height: 100px;
                object-fit: contain;
            }
        }

        td.product-remove {
            a {
                color: #fff !important;
                position: relative;

                &:hover {
                    background-color: #fff !important;

                    &::after {
                        color: lighten($red, 10%) !important;
                    }
                }

                &::after {
                    content:'\0044';
                    display: inline-block;
                    font-family: 'dbs';
                    font-style: normal;
                    font-weight: normal;
                    line-height: 1;
                    color: $red !important;
                    font-size: 14px;
                    position: absolute;
                    top: 0;
                    left: 0;
                    transition: ease all .3s;
                }
            }
        }

        td.product-name {
            a {
                color: #222;
                font-size: 16px;
                font-weight: 400;
                text-decoration: none;
            }
        }

        td.product-price,
        td.product-subtotal {
            .price_small {
                display: none;
            }

            .woocommerce-Price-currencySymbol {
                display: inline-block;
            }

            span {
                margin-bottom: 0px;
            }
            
        }

        tr, th, td {
            border: 0px;
        }

        td.actions {
            border-top: 1px solid #e4ecea;
            padding-top: 20px;
            padding-left: 0px;
            padding-right: 0px;

            button[name="update_cart"] {
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                border-radius: 20px;
                color: #fff;
                background-color: $blue;
                padding: 14px 30px 12px;
                transition: ease all .3s;

                &:hover {
                    background-color: lighten($blue, 5%);
                    box-shadow: 0 8px 24px 0 rgba(68,99,137,.1);
                }
            }

            .coupon {
                #coupon_code {
                    font-size: 14px;
                    font-weight: 300;
                    width: 285px;
                    height: 44px;
                    border: 1px solid #dae3e2;
                    border-radius: 0px;
                    padding-left: 12px;

                    @include responsive('xs') {
                        display: block;
                        width: 100%;
                        margin-bottom: 10px;
                    }
                }

                .button {
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    padding: 14px 30px 12px;
                    background-color: #dae3e2;
                    border-radius: 20px;
                    margin-left: 20px;
                    transition: ease all .3s;
                    cursor: pointer;

                    @include responsive('xs') {
                        display: block;
                        width: 100%;
                    }

                    &:hover {
                        background-color: lighten(#dae3e2, 5%);
                        box-shadow: 0 8px 24px 0 rgba(68,99,137,.05);
                    }
                }
            }
        }
    }

    #customer_details,
    #order_review {
        display: inline-block;
        width: 48%;
        float: left;

        @include responsive('xs') {
            float: none;
            width: 100%;
            margin-right: 0;
        }

        h3 {
            font-size: 24px;
            font-weight: 400;
            color: $red;
            margin-bottom: 25px;
        }

        .woocommerce-additional-fields h3 {
            display: none;
        }

        label {
            font-size: 16px;
            font-weight: 300;
            // color: #5a5a5a;
        }
    }

    #customer_details {
        margin-right: 4%;

        @include responsive('xs') {
            margin-bottom: 30px;
        }
    }

    span {
        margin-bottom: 0;
    }

    #customer_details {
        input {
            height: 40px;
            border: 1px solid #dae3e2;
            display: block;
            width: 100%;
            border-radius: 0px;
            padding-left: 10px;
            font-size: 16px;
            font-weight: 300;
        }
    }
}

form.checkout.woocommerce-checkout {
    margin-top: 50px;
}

.woocommerce form .form-row .required {
    color: #5a5a5a;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
    line-height: 40px;
}

.select2-container .select2-selection--single {
    height: 40px;
    border-radius: 0px;
    border: 1px solid #dae3e2;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 40px;
}

.woocommerce-account-fields {
    input {
        display: inline-block;
        width: auto !important;
        float: left;
    }

    span {
        display: inline-block !important;
        position: relative;
        top: 5px;
        font-size: 14px !important;
        font-weight: 300 !important;
    }
}

.test-content .test-content #ship-to-different-address {
    background-color: #f6f9f9;
    position: relative;

    span {
        display: inline-block;
        padding: 19px 19px 14px;
        font-size: 18px;
        font-weight: 400;
        color: $red;
    }

    input {
        display: inline-block;
        width: auto !important;
        float: right;
        position: absolute;
        top: 50%;
        right: 15px;  
        transform: translate(0%, -50%);
    }
}

#order_comments_field {
    span {
        display: inline-block;
    }

    span.woocommerce-input-wrapper {
        display: block;

        textarea {
            border: 1px solid #dae3e2;
            display: block;
            width: 100%;
            border-radius: 0px;
            padding-left: 10px;
            padding-top: 10px;
            height: 100px;
            font-size: 16px;
            font-weight: 300;
        }
    }
}

table.shop_table.woocommerce-checkout-review-order-table {
    border-collapse: collapse;
    border-right: 1px solid #e4ecea !important;
    border-bottom: 1px solid #e4ecea !important;
    border-left: 1px solid #e4ecea !important;

    span {
        display: inline-block;
        font-size: 14px;
        font-weight: 300;
    }

    thead {
        background-color: #eef3f3 !important;
    }

    .cart-subtotal,
    .order-total {
        background-color: #f6f9f9;

        th {
            font-size: 14px;
            font-weight: 400;
        }
    }

    tbody {
        td {
            padding: 15px 10px !important;
        }
    }

    .shipping {
        th {
            font-size: 14px;
            font-weight: 400;
        }
    }

    .order-total {
        td {
            span {
                font-weight: 400;
            }
        }
    }
}

.woocommerce-checkout #payment {
    border-radius: 0px;
    background-color: #f6f9f9;

    label {
        font-size: 16px !important;
        font-weight: 400 !important;
        line-height: 20px !important;
    }

    div.payment_box {
        background-color: #ebf0ef;

        &::before {
            border: 1em solid #ebf0ef;
            border-right-color: transparent;
            border-left-color: transparent;
            border-top-color: transparent;
        }

        p {
            font-size: 14px;
            font-weight: 300;
            line-height: 20px;
        }
    }

    ul.payment_methods {
        border-bottom: 0px;
        padding-bottom: 0px;
    }
}

.woocommerce-privacy-policy-text a,
.woocommerce-privacy-policy-text a:visited {
    text-decoration: none;
    color: #6d6f71;
    transition: ease all .3s;
    padding-bottom: 1px;
    border-bottom: 1px solid transparent;

    &:hover {
        border-bottom: 1px solid #6d6f71;
    }
}

.woocommerce-terms-and-conditions-wrapper {

    > p {
        margin: 10px 0 !important;
        display: block;
        position: relative;
    
        span {
            display: inline-block !important;
        }

        font-size: 16px;
        font-weight: 400;
        line-height: 20px;

        a {
            text-decoration: none;
            color: #222;
            transition: ease all .3s;
            padding-bottom: 1px;
            border-bottom: 1px solid #222;
        }

        @include responsive('xlg') {
            padding-left: 24px !important;

            input {
                position: absolute;
                top: 8px;
                left: 0;
            }

            span {
                display: inline-block !important;
                float: left;
            }
        }
    }
}

.woocommerce #payment #place_order, .woocommerce-page #payment #place_order {
    float: left;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border-radius: 20px;
    color: #fff;
    background-color: $red;
    padding: 14px 30px 12px;
    transition: ease all .3s;
    margin-top: 15px;

    &:hover {
        background-color: $red;
        box-shadow: 0 8px 24px 0 rgba(68,99,137,.1);
    }
}

@include responsive('xs') {
    .test-content.content-inside.simple-page.checkout-pages {
        .test-content {
            padding-right: 20px;
        }
    }
}