*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
    outline: 0 !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body {
    box-sizing: border-box;
    text-rendering: optimizeLegibility;
    font-family: 'Gilroy';
    overflow-x: hidden;
    font-weight: 400;
    padding-top: 108px;

    @include responsive('xlg') {
        padding-top: 96px;
    }

    @include responsive('md') {
        padding-top: 82px;
    }
}

html {
    -webkit-text-size-adjust: 100%;
    text-size-adjust: 100%;
}

body.overflow {
    overflow-y: hidden;
}

.clearfix {
    display: table;
    clear: both;
}