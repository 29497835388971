header {
    height: 108px;
    box-shadow: 0 10px 4px 1px rgba(95, 114, 115, 0.01);
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 99999;
    background-color: #fff;

    @include responsive('xlg') {
        height: 96px;
    }

    @include responsive('md') {
        height: 82px;
    }
    
    img {
        height: 71px;

        @include responsive('md') {
            height: 49px;
        }
    }

    @include responsive('sm') {
        .brand {
            img {
                height: 50px;
            }
        }
    }

    .navigation {
        list-style: none;
        margin-left: 30px;

        li {
            display: inline-block;
            position: relative;
            &:not(:last-child) {
                margin-right: 26px;

                @include responsive('md') {
                    margin-right: 8px;
                }
            }

            &.active {
                a {
                    color: #000;
                    border-bottom: 1px solid #e4ecea;
                }
            }

            a {
                color: $black;
                font-family: 'Gilroy';
                font-size: 16px;
                font-weight: 500;
                line-height: normal;
                transition: ease all .3s;
                text-decoration: none;
                padding-bottom: 4px;
                border-bottom: 1px solid transparent;
                
                @include responsive('xlg') {
                    font-size: 12px;
                }

                &:hover {
                    color: #000;
                    border-bottom: 1px solid #e4ecea;
                }
            }
        }
    }

    ul {
        display: inline-block;
    }

    ul.login {
        list-style: none;
        float: right;
        position: relative;
        top: -2px;

        @include responsive('sm') {
            margin-right: -60px;
            position: absolute;
            top: 32px;
            right: 0px;

            i {
                font-size: 23px;
            }

            li {
                margin: 0 50px;
                position: relative;

                &:first-child {
                    &::after {
                        content: '';
                        display: block;
                        width: 1px;
                        height: 82px;
                        background-color: #eef3f3;
                        position: absolute;
                        top: -32px;
                        left: -20px;
                    }

                    &::before {
                        content: '';
                        display: block;
                        width: 1px;
                        height: 82px;
                        background-color: #eef3f3;
                        position: absolute;
                        top: -32px;
                        right: -20px;
                    }
                }

                &:nth-child(2) {
                    i {
                        font-size: 24px;
                        position: absolute;
                        top: 45%;
                        right: 0;
                        transform: translate(0,-50%);
                        color: #717171;
                        transition: ease all .3s;

                        &:hover {
                            color: #222;
                        }
                    }
                }
            }
        }
        .test-results {
            a {
                display: inline-block;
                color: #fff;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                border-radius: 20px;
                background-color: $blue;
                text-decoration: none;
                padding: 14px 26px 11px;
                transition: ease all .3s;

                @include responsive('sm') {
                    display: none;
                }

                @include responsive('xlg') {
                    font-size: 12px;
                }

                @include responsive('md') {
                    padding: 10px 10px 6px;
                }

                &:hover {
                    background-color: lighten($blue, 5%);
                }
            }
        }
        li {
            display: inline-block;
            position: relative;

            &:nth-child(2) {
                display: none;

                @include responsive('sm') {
                    display: inline-block;
                }
            }

            &:first-child {
                margin-right: 10px;

                a {
                    color: #717171;
                    font-family: 'Gilroy';
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 24px;
                    text-transform: uppercase;
                    transition: ease all .3s;
                    text-decoration: none;
                    padding-bottom: 4px;
                    border-bottom: 1px solid transparent;
                    margin-right: 22px;

                    @include responsive('xlg') {
                        font-size: 12px;
                    }

                    @include responsive('sm') {
                        &:first-child {
                            display: none;
                        }
                    }

                    i {
                        font-size: 24px;
                        position: absolute;
                        top: 45%;
                        right: 0%;  
                        transform: translate(0%, -50%);
                    }

                    span {
                        width: 19px;
                        height: 19px;
                        background-color: $red;
                        border-radius: 100%;
                        color: #fff;
                        text-align: center;
                        line-height: 19px;
                        display: block;
                        position: absolute;
                        top: -10px;
                        right: -7px;
                        font-size: 12px;
                        font-weight: 400;
                        line-height: 21.97px;
                        padding-left: 3px;
                    }

                    &:hover {
                        color: #000;
                        border-bottom: 1px solid #e4ecea;
                    }
                }
            }

            ul.menu-dropdown {
                display: block;
                position: absolute;
                top: 20px;
                left: -140px;
                width: auto;
                border-radius: 0 10px 10px 10px;
                background-color: #eef3f3;
                margin: 0;
                padding: 10px;
                opacity: 0;
                visibility: hidden;
                transition: ease all .3s;
                z-index: 9999;

                @include responsive('xlg') {
                    width: 104px;
                    left: -120px;
                }

                @include responsive('md') {
                    left: -105px;
                }

                li:nth-child(2) {
                    display: block;
                }

                a {
                    font-size: 14px;
                    font-weight: 400;
                    text-transform: none;
                    white-space: nowrap;

                    &:hover {
                        border: 0px solid transparent;
                        color: $red;
                    }
                }

                li:last-child {
                    a {
                        padding: 0;
                        background-color: transparent;
                        color: #717171;

                        &:hover {
                            border: 0px solid transparent;
                            color: $red;
                        }
                    }
                }
            }
        }
        .languages {
            @include responsive('md') {
                display: none;
            }
            .language-selector {
                height: 27px;
                margin-left: 6px;
            }
            a {
                color: $black !important;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 20px;
                text-transform: uppercase;
                text-decoration: none;
            }
            .lang-toggle {
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
                color: $black;
                text-transform: uppercase;
                height: 100%;
                width: 53px;
                z-index: 103;
                &:after {
                    content: url("../includes/src/img/icn-arrow-down.svg");
                    margin-left: 6px;
                    margin-top: -5px;
                }
            }
            .lang-dropdown {
                display: none;
                position: absolute;
                transform: translate(2px, 0px) !important;
                background-color: $white;
                list-style-type: none;
                padding-left: 0;
                z-index: 102;
                padding-top: 5px;
                .lang-item {
                    display: flex;
                    margin-right: 0;
                    a {
                        color: $black !important;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 20px;
                        text-transform: uppercase;
                        width: 53px;
                        max-width: 53px;
                        align-items: center;
                        display: flex;
                        padding: 8px;
                        margin-right: unset;
                    }
                    &:hover {
                        a {
                            color: $blue !important;
                            border-bottom: unset;
                        }
                    }
                }
            }
        }
    }
}

@include responsive('sm') {
    .container {
        width: 100%;
    }
}

.my-a {
    position: absolute;
    left: -140px;
    top: -8px;
    white-space: nowrap;
    font-weight: 600 !important;
    background-color: #eef3f3;
    padding: 8px;
    border-radius: 10px;

    @include responsive('xlg') {
        left: -120px;
    }

    @include responsive('md') {
        left: -105px;
        top: -8px;
    }

}

.all-warpper {
    &:hover {
        ul.menu-dropdown {
            opacity: 1;
            visibility: visible;
        }
    }
}