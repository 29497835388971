.woocommerce-order-details {
    span {
        display: inline-block !important;
    }
}

.woocommerce-order-details__title {
    margin-bottom: 20px;
}

.woocommerce-order-overview span {
    display: inline-block !important;
}

.woocommerce ul.order_details li {
    @include responsive('xs') {
        display: block;
        width: 100%;
        float: none;
        margin-bottom: 20px;
    }
}