.clearfix {
    display: table;
    content: '';
    clear: both;
}

.testimonials.in-pages {
    background-color: #f5f6f7;
}

.summary.entry-summary {
    width: 100% !important;
    float: none !important;
    display: block;
}

.product-top {
    background-color: #f5f6f7;
    padding-bottom: 20px;

    .product_meta {
        display: none;
    }

    h1.product_title.entry-title {
        font-size: 24px;
        font-weight: 600;
        text-transform: uppercase;
        color: $red;
        margin-bottom: 14px;

    }

    .subtitle {
        font-weight: 400;
        line-height: 150%;
        margin-bottom: 20px;
        font-size: 18px;
    }

    .woocommerce-product-details__short-description {
        p {
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            color: $grey;
            max-width: 525px;
        }

        margin-bottom: 30px;
    }

    .new_text {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        color: $grey;
        margin-bottom: 6px;

        b {
            color: #505151;
            font-weight: 400;
        }
    }

    .price {
        margin: 30px 0;
        color: $grey;

        .price_small {
            font-size: 14px;
            font-weight: 400;
            color: $grey;
            margin-right: 6px;
        }

        .woocommerce-Price-amount.amount {
            font-size: 24px;
            color: #222222;
        }
    }

    .cart {
        .single_add_to_cart_button {
            display: inline-block;
            text-transform: uppercase;
            text-decoration: none;
            color: #fff;
            padding: 15px 30px 12px;
            background-color: $red !important;
            border-radius: 20px;
            position: relative;
            top: -2px;
            transition: ease all .4s;
            font-weight: 400;

            &:hover {
                background-color: lighten($red, 5%) !important;
                box-shadow: 0 8px 24px 0 rgba(68,99,137,.1);
            }
        }
    }
}

.woocommerce-notices-wrapper {
    margin: 0 15px;

    .woocommerce-message {
        margin-bottom: 0px;
        border-top-color: $red;

        &::before {
            color: $red;
        }
    }
}

.woocommerce div.product form.cart .button,
.woocommerce div.product form.cart div.quantity {
    float: none;
}

.plus,
.quantity,
.minus,
.qty-u,
.qty-m {
    display: inline-block;
}

.woocommerce div.product form.cart div.quantity {
    margin: 0;
}

.quantity {
    position: relative;
    left: -6px;
}

.qty {
    appearance: textfield;
    width: 48px !important;
    height: 43px;
    border: 1px solid #b5c5c4;
    border-radius: 0 !important;
    position: relative;
    top: 1px;
    color: #000000;
    font-size: 18px;
    font-weight: 400;
    text-transform: uppercase;
    background-color: transparent;

    &::-webkit-inner-spin-button, 
    &::-webkit-outer-spin-button { 
        -webkit-appearance: none; 
        margin: 0; 
    }
}

.minus,
.qty-m {
    border: 1px solid #b5c5c4;
    border-radius: 10px 0 0 10px;
    background-color: transparent;
    width: 46px;
    height: 43px;
    cursor: pointer;
    transition: ease all .3s;
    position: relative;
    left: -97px;
    top: 16px;

    &:hover {
        background-color: $red;
        border: 1px solid $red;

        &::after {
            color: #fff;
        }
    }

    &::after {
        content:'\0046';
        display: block;
        width: 20px;
        height: 20px;
        font-family: 'dbs';
        font-style: normal;
        font-weight: normal;
        line-height: 1;
        color: $red;
        position: absolute;
        top: 56%;
        left: 50%;  
        transform: translate(-50%, -50%);
        font-size: 14px;
        transition: ease all .3s;
    }

    @include responsive('xs') {
        top: 17px;
    }
}

.quantity {
    width: 150px;
    text-align: center;
    left: 43px;
    height: 43px;
}

.plus,
.qty-u {
    border: 1px solid #b5c5c4;
    border-radius: 0 10px 10px 0;
    background-color: transparent;
    width: 46px;
    height: 43px;
    position: relative;
    position: relative;
    left: -51px;
    top: 16px;

    cursor: pointer;
    transition: ease all .3s;

    &:hover {
        background-color: $red;
        border: 1px solid $red;

        &::after {
            color: #fff;
        }
    }

    &::after {
        content:'\0041';
        display: block;
        width: 20px;
        height: 20px;
        font-family: 'dbs';
        font-style: normal;
        font-weight: normal;
        line-height: 1;
        color: $red;
        position: absolute;
        top: 60%;
        left: 50%;  
        transform: translate(-50%, -50%);
        font-size: 14px;
        transition: ease all .3s;
    }

    @include responsive('xs') {
        top: 17px;
    }
}

.shop_table {
    .quantity {
        left: 40px;
        top: -14px;

        span {
            display: inline-block;
            top: 18px;

            &.qty-m {
                left: -97px;
            }

            &.qty-u {
                left: -51px;
            }
        }
    }
}

@include responsive('ss') {
    .woocommerce div.product form.cart .button {
        padding: 15px 16px 12px;
    }
}