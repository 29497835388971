@import '/node_modules/lightgallery/dist/css/lightgallery.min.css';
@import '/node_modules/lightslider/dist/css/lightslider.css';
@import url("https://fonts.googleapis.com/css?family=Nunito:300,400,600");
@font-face {
  font-family: "Gilroy";
  src: url("../includes/src/fonts/Gilroy-Regular.eot");
  src: local("Gilroy Regular"), local("Gilroy-Regular"), url("../includes/src/fonts/Gilroy-Regular.eot?#iefix") format("embedded-opentype"), url("../includes/src/fonts/Gilroy-Regular.woff2") format("woff2"), url("../includes/src/fonts/Gilroy-Regular.woff") format("woff"), url("../includes/src/fonts/Gilroy-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Gilroy";
  src: url("../includes/src/fonts/Gilroy-ExtraboldItalic.eot");
  src: local("Gilroy Extrabold Italic"), local("Gilroy-ExtraboldItalic"), url("../includes/src/fonts/Gilroy-ExtraboldItalic.eot?#iefix") format("embedded-opentype"), url("../includes/src/fonts/Gilroy-ExtraboldItalic.woff2") format("woff2"), url("../includes/src/fonts/Gilroy-ExtraboldItalic.woff") format("woff"), url("../includes/src/fonts/Gilroy-ExtraboldItalic.ttf") format("truetype");
  font-weight: 800;
  font-style: italic;
}
@font-face {
  font-family: "Gilroy";
  src: url("../includes/src/fonts/Gilroy-Bold.eot");
  src: local("Gilroy Bold"), local("Gilroy-Bold"), url("../includes/src/fonts/Gilroy-Bold.eot?#iefix") format("embedded-opentype"), url("../includes/src/fonts/Gilroy-Bold.woff2") format("woff2"), url("../includes/src/fonts/Gilroy-Bold.woff") format("woff"), url("../includes/src/fonts/Gilroy-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "Gilroy";
  src: url("../includes/src/fonts/Gilroy-Black.eot");
  src: local("Gilroy Black"), local("Gilroy-Black"), url("../includes/src/fonts/Gilroy-Black.eot?#iefix") format("embedded-opentype"), url("../includes/src/fonts/Gilroy-Black.woff2") format("woff2"), url("../includes/src/fonts/Gilroy-Black.woff") format("woff"), url("../includes/src/fonts/Gilroy-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: "Gilroy";
  src: url("../includes/src/fonts/Gilroy-Light.eot");
  src: local("Gilroy Light"), local("Gilroy-Light"), url("../includes/src/fonts/Gilroy-Light.eot?#iefix") format("embedded-opentype"), url("../includes/src/fonts/Gilroy-Light.woff2") format("woff2"), url("../includes/src/fonts/Gilroy-Light.woff") format("woff"), url("../includes/src/fonts/Gilroy-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Gilroy";
  src: url("../includes/src/fonts/Gilroy-Semibold.eot");
  src: local("Gilroy Semibold"), local("Gilroy-Semibold"), url("../includes/src/fonts/Gilroy-Semibold.eot?#iefix") format("embedded-opentype"), url("../includes/src/fonts/Gilroy-Semibold.woff2") format("woff2"), url("../includes/src/fonts/Gilroy-Semibold.woff") format("woff"), url("../includes/src/fonts/Gilroy-Semibold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Gilroy";
  src: url("../includes/src/fonts/Gilroy-Medium.eot");
  src: local("Gilroy Medium"), local("Gilroy-Medium"), url("../includes/src/fonts/Gilroy-Medium.eot?#iefix") format("embedded-opentype"), url("../includes/src/fonts/Gilroy-Medium.woff2") format("woff2"), url("../includes/src/fonts/Gilroy-Medium.woff") format("woff"), url("../includes/src/fonts/Gilroy-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Gilroy";
  src: url("../includes/src/fonts/Gilroy-MediumItalic.eot");
  src: local("Gilroy Medium Italic"), local("Gilroy-MediumItalic"), url("../includes/src/fonts/Gilroy-MediumItalic.eot?#iefix") format("embedded-opentype"), url("../includes/src/fonts/Gilroy-MediumItalic.woff2") format("woff2"), url("../includes/src/fonts/Gilroy-MediumItalic.woff") format("woff"), url("../includes/src/fonts/Gilroy-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: "Gilroy";
  src: url("../includes/src/fonts/Gilroy-BlackItalic.eot");
  src: local("Gilroy Black Italic"), local("Gilroy-BlackItalic"), url("../includes/src/fonts/Gilroy-BlackItalic.eot?#iefix") format("embedded-opentype"), url("../includes/src/fonts/Gilroy-BlackItalic.woff2") format("woff2"), url("../includes/src/fonts/Gilroy-BlackItalic.woff") format("woff"), url("../includes/src/fonts/Gilroy-BlackItalic.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
}
@font-face {
  font-family: "Gilroy";
  src: url("../includes/src/fonts/Gilroy-RegularItalic.eot");
  src: local("Gilroy Regular Italic"), local("Gilroy-RegularItalic"), url("../includes/src/fonts/Gilroy-RegularItalic.eot?#iefix") format("embedded-opentype"), url("../includes/src/fonts/Gilroy-RegularItalic.woff2") format("woff2"), url("../includes/src/fonts/Gilroy-RegularItalic.woff") format("woff"), url("../includes/src/fonts/Gilroy-RegularItalic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: "Gilroy";
  src: url("../includes/src/fonts/Gilroy-SemiboldItalic.eot");
  src: local("Gilroy Semibold Italic"), local("Gilroy-SemiboldItalic"), url("../includes/src/fonts/Gilroy-SemiboldItalic.eot?#iefix") format("embedded-opentype"), url("../includes/src/fonts/Gilroy-SemiboldItalic.woff2") format("woff2"), url("../includes/src/fonts/Gilroy-SemiboldItalic.woff") format("woff"), url("../includes/src/fonts/Gilroy-SemiboldItalic.ttf") format("truetype");
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: "Gilroy";
  src: url("../includes/src/fonts/Gilroy-Extrabold.eot");
  src: local("Gilroy Extrabold"), local("Gilroy-Extrabold"), url("../includes/src/fonts/Gilroy-Extrabold.eot?#iefix") format("embedded-opentype"), url("../includes/src/fonts/Gilroy-Extrabold.woff2") format("woff2"), url("../includes/src/fonts/Gilroy-Extrabold.woff") format("woff"), url("../includes/src/fonts/Gilroy-Extrabold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: "Gilroy";
  src: url("../includes/src/fonts/Gilroy-BoldItalic.eot");
  src: local("Gilroy Bold Italic"), local("Gilroy-BoldItalic"), url("../includes/src/fonts/Gilroy-BoldItalic.eot?#iefix") format("embedded-opentype"), url("../includes/src/fonts/Gilroy-BoldItalic.woff2") format("woff2"), url("../includes/src/fonts/Gilroy-BoldItalic.woff") format("woff"), url("../includes/src/fonts/Gilroy-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: "Gilroy";
  src: url("../includes/src/fonts/Gilroy-LightItalic.eot");
  src: local("Gilroy Light Italic"), local("Gilroy-LightItalic"), url("../includes/src/fonts/Gilroy-LightItalic.eot?#iefix") format("embedded-opentype"), url("../includes/src/fonts/Gilroy-LightItalic.woff2") format("woff2"), url("../includes/src/fonts/Gilroy-LightItalic.woff") format("woff"), url("../includes/src/fonts/Gilroy-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}
html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
}

body {
  margin: 0;
}

main {
  display: block;
}

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace, monospace;
  font-size: 1em;
}

a {
  background-color: transparent;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted;
}

b,
strong {
  font-weight: bolder;
}

code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

img {
  border-style: none;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type=button]:-moz-focusring,
[type=reset]:-moz-focusring,
[type=submit]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: 0.35em 0.75em 0.625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type=checkbox],
[type=radio] {
  box-sizing: border-box;
  padding: 0;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details {
  display: block;
}

summary {
  display: list-item;
}

template {
  display: none;
}

[hidden] {
  display: none;
}

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
  outline: 0 !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  box-sizing: border-box;
  text-rendering: optimizeLegibility;
  font-family: "Gilroy";
  overflow-x: hidden;
  font-weight: 400;
  padding-top: 108px;
}
@media (max-width: 1400px) {
  body {
    padding-top: 96px;
  }
}
@media (max-width: 1200px) {
  body {
    padding-top: 82px;
  }
}

html {
  -webkit-text-size-adjust: 100%;
  text-size-adjust: 100%;
}

body.overflow {
  overflow-y: hidden;
}

.clearfix {
  display: table;
  clear: both;
}

@font-face {
  font-family: "dbs";
  font-display: swap;
  src: url("../assets/fonts/dbs.eot");
  src: url("../assets/fonts/dbs.eot?#iefix") format("embedded-opentype"), url("../assets/fonts/dbs.woff") format("woff"), url("../assets/fonts/dbs.ttf") format("truetype"), url("../assets/fonts/dbs.svg#dbs") format("svg");
  font-weight: normal;
  font-style: normal;
}
[class*=dbs-]:before {
  display: inline-block;
  font-family: "dbs";
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.dbs-plus:before {
  content: "A";
}

.dbs-search:before {
  content: "B";
}

.dbs-bag:before {
  content: "C";
}

.dbs-close:before {
  content: "D";
}

.dbs-email:before {
  content: "E";
}

.dbs-minus:before {
  content: "F";
}

.dbs-pdf:before {
  content: "G";
}

.dbs-back:before {
  content: "H";
}

.container {
  width: 1380px;
  margin: 0 auto;
}
@media (max-width: 1400px) {
  .container {
    width: 1170px;
  }
}
@media (max-width: 1200px) {
  .container {
    width: 970px;
  }
}
@media (max-width: 992px) {
  .container {
    width: 750px;
  }
}
@media (max-width: 767px) {
  .container {
    width: 100%;
  }
}

.row {
  box-sizing: border-box;
  margin: 0 auto;
  width: 100%;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
}

.row.reverse {
  flex-direction: row-reverse;
}

.row.natural-height {
  align-items: flex-start;
}

.row.flex-column {
  flex-direction: column;
}

.col {
  box-sizing: border-box;
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
  padding: 1rem;
}

.col.reverse {
  flex-direction: column-reverse;
}

.first {
  order: -1;
}

.last {
  order: 1;
}

.align-start {
  align-self: flex-start;
}

.align-end {
  align-self: flex-end;
}

.align-center {
  align-self: center;
}

.align-baseline {
  align-self: baseline;
}

.align-stretch {
  align-self: stretch;
}

.col-xs {
  box-sizing: border-box;
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
  padding: 1rem;
}

.col-xs-1 {
  box-sizing: border-box;
  flex-basis: 8.3333333333%;
  max-width: 8.3333333333%;
  padding: 1rem;
}

.col-xs-offset-1 {
  margin-left: 8.3333333333%;
}

.col-xs-2 {
  box-sizing: border-box;
  flex-basis: 16.6666666667%;
  max-width: 16.6666666667%;
  padding: 1rem;
}

.col-xs-offset-2 {
  margin-left: 16.6666666667%;
}

.col-xs-3 {
  box-sizing: border-box;
  flex-basis: 25%;
  max-width: 25%;
  padding: 1rem;
}

.col-xs-offset-3 {
  margin-left: 25%;
}

.col-xs-4 {
  box-sizing: border-box;
  flex-basis: 33.3333333333%;
  max-width: 33.3333333333%;
  padding: 1rem;
}

.col-xs-offset-4 {
  margin-left: 33.3333333333%;
}

.col-xs-5 {
  box-sizing: border-box;
  flex-basis: 41.6666666667%;
  max-width: 41.6666666667%;
  padding: 1rem;
}

.col-xs-offset-5 {
  margin-left: 41.6666666667%;
}

.col-xs-6 {
  box-sizing: border-box;
  flex-basis: 50%;
  max-width: 50%;
  padding: 1rem;
}

.col-xs-offset-6 {
  margin-left: 50%;
}

.col-xs-7 {
  box-sizing: border-box;
  flex-basis: 58.3333333333%;
  max-width: 58.3333333333%;
  padding: 1rem;
}

.col-xs-offset-7 {
  margin-left: 58.3333333333%;
}

.col-xs-8 {
  box-sizing: border-box;
  flex-basis: 66.6666666667%;
  max-width: 66.6666666667%;
  padding: 1rem;
}

.col-xs-offset-8 {
  margin-left: 66.6666666667%;
}

.col-xs-9 {
  box-sizing: border-box;
  flex-basis: 75%;
  max-width: 75%;
  padding: 1rem;
}

.col-xs-offset-9 {
  margin-left: 75%;
}

.col-xs-10 {
  box-sizing: border-box;
  flex-basis: 83.3333333333%;
  max-width: 83.3333333333%;
  padding: 1rem;
}

.col-xs-offset-10 {
  margin-left: 83.3333333333%;
}

.col-xs-11 {
  box-sizing: border-box;
  flex-basis: 91.6666666667%;
  max-width: 91.6666666667%;
  padding: 1rem;
}

.col-xs-offset-11 {
  margin-left: 91.6666666667%;
}

.col-xs-12 {
  box-sizing: border-box;
  flex-basis: 100%;
  max-width: 100%;
  padding: 1rem;
}

.col-xs-offset-12 {
  margin-left: 100%;
}

.row.start-xs {
  justify-content: flex-start;
}

.row.center-xs {
  justify-content: center;
}

.row.end-xs {
  justify-content: flex-end;
}

.row.top-xs {
  align-items: flex-start;
}

.row.middle-xs {
  align-items: center;
}

.row.bottom-xs {
  align-items: flex-end;
}

.row.around-xs {
  justify-content: space-around;
}

.row.between-xs {
  justify-content: space-between;
}

.first-xs {
  order: -1;
}

.last-xs {
  order: 1;
}

@media only screen and (min-width: 768px) {
  .col-sm {
    box-sizing: border-box;
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
    padding: 1rem;
  }
  .col-sm-1 {
    box-sizing: border-box;
    flex-basis: 8.3333333333%;
    max-width: 8.3333333333%;
    padding: 1rem;
  }
  .col-sm-offset-1 {
    margin-left: 8.3333333333%;
  }
  .col-sm-2 {
    box-sizing: border-box;
    flex-basis: 16.6666666667%;
    max-width: 16.6666666667%;
    padding: 1rem;
  }
  .col-sm-offset-2 {
    margin-left: 16.6666666667%;
  }
  .col-sm-3 {
    box-sizing: border-box;
    flex-basis: 25%;
    max-width: 25%;
    padding: 1rem;
  }
  .col-sm-offset-3 {
    margin-left: 25%;
  }
  .col-sm-4 {
    box-sizing: border-box;
    flex-basis: 33.3333333333%;
    max-width: 33.3333333333%;
    padding: 1rem;
  }
  .col-sm-offset-4 {
    margin-left: 33.3333333333%;
  }
  .col-sm-5 {
    box-sizing: border-box;
    flex-basis: 41.6666666667%;
    max-width: 41.6666666667%;
    padding: 1rem;
  }
  .col-sm-offset-5 {
    margin-left: 41.6666666667%;
  }
  .col-sm-6 {
    box-sizing: border-box;
    flex-basis: 50%;
    max-width: 50%;
    padding: 1rem;
  }
  .col-sm-offset-6 {
    margin-left: 50%;
  }
  .col-sm-7 {
    box-sizing: border-box;
    flex-basis: 58.3333333333%;
    max-width: 58.3333333333%;
    padding: 1rem;
  }
  .col-sm-offset-7 {
    margin-left: 58.3333333333%;
  }
  .col-sm-8 {
    box-sizing: border-box;
    flex-basis: 66.6666666667%;
    max-width: 66.6666666667%;
    padding: 1rem;
  }
  .col-sm-offset-8 {
    margin-left: 66.6666666667%;
  }
  .col-sm-9 {
    box-sizing: border-box;
    flex-basis: 75%;
    max-width: 75%;
    padding: 1rem;
  }
  .col-sm-offset-9 {
    margin-left: 75%;
  }
  .col-sm-10 {
    box-sizing: border-box;
    flex-basis: 83.3333333333%;
    max-width: 83.3333333333%;
    padding: 1rem;
  }
  .col-sm-offset-10 {
    margin-left: 83.3333333333%;
  }
  .col-sm-11 {
    box-sizing: border-box;
    flex-basis: 91.6666666667%;
    max-width: 91.6666666667%;
    padding: 1rem;
  }
  .col-sm-offset-11 {
    margin-left: 91.6666666667%;
  }
  .col-sm-12 {
    box-sizing: border-box;
    flex-basis: 100%;
    max-width: 100%;
    padding: 1rem;
  }
  .col-sm-offset-12 {
    margin-left: 100%;
  }
  .row.start-sm {
    justify-content: flex-start;
  }
  .row.center-sm {
    justify-content: center;
  }
  .row.end-sm {
    justify-content: flex-end;
  }
  .row.top-sm {
    align-items: flex-start;
  }
  .row.middle-sm {
    align-items: center;
  }
  .row.bottom-sm {
    align-items: flex-end;
  }
  .row.around-sm {
    justify-content: space-around;
  }
  .row.between-sm {
    justify-content: space-between;
  }
  .first-sm {
    order: -1;
  }
  .last-sm {
    order: 1;
  }
}
@media only screen and (min-width: 993px) {
  .col-md {
    box-sizing: border-box;
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
    padding: 1rem;
  }
  .col-md-1 {
    box-sizing: border-box;
    flex-basis: 8.3333333333%;
    max-width: 8.3333333333%;
    padding: 1rem;
  }
  .col-md-offset-1 {
    margin-left: 8.3333333333%;
  }
  .col-md-2 {
    box-sizing: border-box;
    flex-basis: 16.6666666667%;
    max-width: 16.6666666667%;
    padding: 1rem;
  }
  .col-md-offset-2 {
    margin-left: 16.6666666667%;
  }
  .col-md-3 {
    box-sizing: border-box;
    flex-basis: 25%;
    max-width: 25%;
    padding: 1rem;
  }
  .col-md-offset-3 {
    margin-left: 25%;
  }
  .col-md-4 {
    box-sizing: border-box;
    flex-basis: 33.3333333333%;
    max-width: 33.3333333333%;
    padding: 1rem;
  }
  .col-md-offset-4 {
    margin-left: 33.3333333333%;
  }
  .col-md-5 {
    box-sizing: border-box;
    flex-basis: 41.6666666667%;
    max-width: 41.6666666667%;
    padding: 1rem;
  }
  .col-md-offset-5 {
    margin-left: 41.6666666667%;
  }
  .col-md-6 {
    box-sizing: border-box;
    flex-basis: 50%;
    max-width: 50%;
    padding: 1rem;
  }
  .col-md-offset-6 {
    margin-left: 50%;
  }
  .col-md-7 {
    box-sizing: border-box;
    flex-basis: 58.3333333333%;
    max-width: 58.3333333333%;
    padding: 1rem;
  }
  .col-md-offset-7 {
    margin-left: 58.3333333333%;
  }
  .col-md-8 {
    box-sizing: border-box;
    flex-basis: 66.6666666667%;
    max-width: 66.6666666667%;
    padding: 1rem;
  }
  .col-md-offset-8 {
    margin-left: 66.6666666667%;
  }
  .col-md-9 {
    box-sizing: border-box;
    flex-basis: 75%;
    max-width: 75%;
    padding: 1rem;
  }
  .col-md-offset-9 {
    margin-left: 75%;
  }
  .col-md-10 {
    box-sizing: border-box;
    flex-basis: 83.3333333333%;
    max-width: 83.3333333333%;
    padding: 1rem;
  }
  .col-md-offset-10 {
    margin-left: 83.3333333333%;
  }
  .col-md-11 {
    box-sizing: border-box;
    flex-basis: 91.6666666667%;
    max-width: 91.6666666667%;
    padding: 1rem;
  }
  .col-md-offset-11 {
    margin-left: 91.6666666667%;
  }
  .col-md-12 {
    box-sizing: border-box;
    flex-basis: 100%;
    max-width: 100%;
    padding: 1rem;
  }
  .col-md-offset-12 {
    margin-left: 100%;
  }
  .row.start-md {
    justify-content: flex-start;
  }
  .row.center-md {
    justify-content: center;
  }
  .row.end-md {
    justify-content: flex-end;
  }
  .row.top-md {
    align-items: flex-start;
  }
  .row.middle-md {
    align-items: center;
  }
  .row.bottom-md {
    align-items: flex-end;
  }
  .row.around-md {
    justify-content: space-around;
  }
  .row.between-md {
    justify-content: space-between;
  }
  .first-md {
    order: -1;
  }
  .last-md {
    order: 1;
  }
}
@media only screen and (min-width: 1201px) {
  .col-lg {
    box-sizing: border-box;
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
    padding: 1rem;
  }
  .col-lg-1 {
    box-sizing: border-box;
    flex-basis: 8.3333333333%;
    max-width: 8.3333333333%;
    padding: 1rem;
  }
  .col-lg-offset-1 {
    margin-left: 8.3333333333%;
  }
  .col-lg-2 {
    box-sizing: border-box;
    flex-basis: 16.6666666667%;
    max-width: 16.6666666667%;
    padding: 1rem;
  }
  .col-lg-offset-2 {
    margin-left: 16.6666666667%;
  }
  .col-lg-3 {
    box-sizing: border-box;
    flex-basis: 25%;
    max-width: 25%;
    padding: 1rem;
  }
  .col-lg-offset-3 {
    margin-left: 25%;
  }
  .col-lg-4 {
    box-sizing: border-box;
    flex-basis: 33.3333333333%;
    max-width: 33.3333333333%;
    padding: 1rem;
  }
  .col-lg-offset-4 {
    margin-left: 33.3333333333%;
  }
  .col-lg-5 {
    box-sizing: border-box;
    flex-basis: 41.6666666667%;
    max-width: 41.6666666667%;
    padding: 1rem;
  }
  .col-lg-offset-5 {
    margin-left: 41.6666666667%;
  }
  .col-lg-6 {
    box-sizing: border-box;
    flex-basis: 50%;
    max-width: 50%;
    padding: 1rem;
  }
  .col-lg-offset-6 {
    margin-left: 50%;
  }
  .col-lg-7 {
    box-sizing: border-box;
    flex-basis: 58.3333333333%;
    max-width: 58.3333333333%;
    padding: 1rem;
  }
  .col-lg-offset-7 {
    margin-left: 58.3333333333%;
  }
  .col-lg-8 {
    box-sizing: border-box;
    flex-basis: 66.6666666667%;
    max-width: 66.6666666667%;
    padding: 1rem;
  }
  .col-lg-offset-8 {
    margin-left: 66.6666666667%;
  }
  .col-lg-9 {
    box-sizing: border-box;
    flex-basis: 75%;
    max-width: 75%;
    padding: 1rem;
  }
  .col-lg-offset-9 {
    margin-left: 75%;
  }
  .col-lg-10 {
    box-sizing: border-box;
    flex-basis: 83.3333333333%;
    max-width: 83.3333333333%;
    padding: 1rem;
  }
  .col-lg-offset-10 {
    margin-left: 83.3333333333%;
  }
  .col-lg-11 {
    box-sizing: border-box;
    flex-basis: 91.6666666667%;
    max-width: 91.6666666667%;
    padding: 1rem;
  }
  .col-lg-offset-11 {
    margin-left: 91.6666666667%;
  }
  .col-lg-12 {
    box-sizing: border-box;
    flex-basis: 100%;
    max-width: 100%;
    padding: 1rem;
  }
  .col-lg-offset-12 {
    margin-left: 100%;
  }
  .row.start-lg {
    justify-content: flex-start;
  }
  .row.center-lg {
    justify-content: center;
  }
  .row.end-lg {
    justify-content: flex-end;
  }
  .row.top-lg {
    align-items: flex-start;
  }
  .row.middle-lg {
    align-items: center;
  }
  .row.bottom-lg {
    align-items: flex-end;
  }
  .row.around-lg {
    justify-content: space-around;
  }
  .row.between-lg {
    justify-content: space-between;
  }
  .first-lg {
    order: -1;
  }
  .last-lg {
    order: 1;
  }
}
@media only screen and (min-width: 1301px) {
  .col-xl {
    box-sizing: border-box;
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
    padding: 1rem;
  }
  .col-xl-1 {
    box-sizing: border-box;
    flex-basis: 8.3333333333%;
    max-width: 8.3333333333%;
    padding: 1rem;
  }
  .col-xl-offset-1 {
    margin-left: 8.3333333333%;
  }
  .col-xl-2 {
    box-sizing: border-box;
    flex-basis: 16.6666666667%;
    max-width: 16.6666666667%;
    padding: 1rem;
  }
  .col-xl-offset-2 {
    margin-left: 16.6666666667%;
  }
  .col-xl-3 {
    box-sizing: border-box;
    flex-basis: 25%;
    max-width: 25%;
    padding: 1rem;
  }
  .col-xl-offset-3 {
    margin-left: 25%;
  }
  .col-xl-4 {
    box-sizing: border-box;
    flex-basis: 33.3333333333%;
    max-width: 33.3333333333%;
    padding: 1rem;
  }
  .col-xl-offset-4 {
    margin-left: 33.3333333333%;
  }
  .col-xl-5 {
    box-sizing: border-box;
    flex-basis: 41.6666666667%;
    max-width: 41.6666666667%;
    padding: 1rem;
  }
  .col-xl-offset-5 {
    margin-left: 41.6666666667%;
  }
  .col-xl-6 {
    box-sizing: border-box;
    flex-basis: 50%;
    max-width: 50%;
    padding: 1rem;
  }
  .col-xl-offset-6 {
    margin-left: 50%;
  }
  .col-xl-7 {
    box-sizing: border-box;
    flex-basis: 58.3333333333%;
    max-width: 58.3333333333%;
    padding: 1rem;
  }
  .col-xl-offset-7 {
    margin-left: 58.3333333333%;
  }
  .col-xl-8 {
    box-sizing: border-box;
    flex-basis: 66.6666666667%;
    max-width: 66.6666666667%;
    padding: 1rem;
  }
  .col-xl-offset-8 {
    margin-left: 66.6666666667%;
  }
  .col-xl-9 {
    box-sizing: border-box;
    flex-basis: 75%;
    max-width: 75%;
    padding: 1rem;
  }
  .col-xl-offset-9 {
    margin-left: 75%;
  }
  .col-xl-10 {
    box-sizing: border-box;
    flex-basis: 83.3333333333%;
    max-width: 83.3333333333%;
    padding: 1rem;
  }
  .col-xl-offset-10 {
    margin-left: 83.3333333333%;
  }
  .col-xl-11 {
    box-sizing: border-box;
    flex-basis: 91.6666666667%;
    max-width: 91.6666666667%;
    padding: 1rem;
  }
  .col-xl-offset-11 {
    margin-left: 91.6666666667%;
  }
  .col-xl-12 {
    box-sizing: border-box;
    flex-basis: 100%;
    max-width: 100%;
    padding: 1rem;
  }
  .col-xl-offset-12 {
    margin-left: 100%;
  }
  .row.start-xl {
    justify-content: flex-start;
  }
  .row.center-xl {
    justify-content: center;
  }
  .row.end-xl {
    justify-content: flex-end;
  }
  .row.top-xl {
    align-items: flex-start;
  }
  .row.middle-xl {
    align-items: center;
  }
  .row.bottom-xl {
    align-items: flex-end;
  }
  .row.around-xl {
    justify-content: space-around;
  }
  .row.between-xl {
    justify-content: space-between;
  }
  .first-xl {
    order: -1;
  }
  .last-xl {
    order: 1;
  }
}
.col-gutter-lr {
  padding: 0 1rem;
}

.col-no-gutter {
  padding: 0;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.show {
  display: block !important;
}

.row.show {
  display: -webkit-flex !important;
  display: -ms-flexbox !important;
  display: flex !important;
}

.hide {
  display: none !important;
}

.show-xs {
  display: block !important;
}

.row.show-xs {
  display: -webkit-flex !important;
  display: -ms-flexbox !important;
  display: flex !important;
}

.hide-xs {
  display: none !important;
}

@media only screen and (max-width: 767px) {
  .show-xs-only {
    display: block !important;
  }
  .row.show-xs-only {
    display: -webkit-flex !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .hide-xs-only {
    display: none !important;
  }
}
@media only screen and (min-width: 768px) {
  .show-sm {
    display: block !important;
  }
  .row.show-sm {
    display: -webkit-flex !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .hide-sm {
    display: none !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 992px) {
  .show-sm-only {
    display: block !important;
  }
  .row.show-sm-only {
    display: -webkit-flex !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .hide-sm-only {
    display: none !important;
  }
}
@media only screen and (min-width: 993px) {
  .show-md {
    display: block !important;
  }
  .row.show-md {
    display: -webkit-flex !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .hide-md {
    display: none !important;
  }
}
@media only screen and (min-width: 993px) and (max-width: 1200px) {
  .show-md-only {
    display: block !important;
  }
  .row.show-md-only {
    display: -webkit-flex !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .hide-md-only {
    display: none !important;
  }
}
@media only screen and (min-width: 1201px) {
  .show-lg {
    display: block !important;
  }
  .row.show-lg {
    display: -webkit-flex !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .hide-lg {
    display: none !important;
  }
}
@media only screen and (min-width: 1201px) and (max-width: 1300px) {
  .show-lg-only {
    display: block !important;
  }
  .row.show-lg-only {
    display: -webkit-flex !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .hide-lg-only {
    display: none !important;
  }
}
@media only screen and (min-width: 1301px) {
  .show-xl {
    display: block !important;
  }
  .row.show-xl {
    display: -webkit-flex !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .hide-xl {
    display: none !important;
  }
}
header {
  height: 108px;
  box-shadow: 0 10px 4px 1px rgba(95, 114, 115, 0.01);
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99999;
  background-color: #fff;
}
@media (max-width: 1400px) {
  header {
    height: 96px;
  }
}
@media (max-width: 1200px) {
  header {
    height: 82px;
  }
}
header img {
  height: 71px;
}
@media (max-width: 1200px) {
  header img {
    height: 49px;
  }
}
@media (max-width: 992px) {
  header .brand img {
    height: 50px;
  }
}
header .navigation {
  list-style: none;
  margin-left: 30px;
}
header .navigation li {
  display: inline-block;
  position: relative;
}
header .navigation li:not(:last-child) {
  margin-right: 26px;
}
@media (max-width: 1200px) {
  header .navigation li:not(:last-child) {
    margin-right: 8px;
  }
}
header .navigation li.active a {
  color: #000;
  border-bottom: 1px solid #e4ecea;
}
header .navigation li a {
  color: #050708;
  font-family: "Gilroy";
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  transition: ease all 0.3s;
  text-decoration: none;
  padding-bottom: 4px;
  border-bottom: 1px solid transparent;
}
@media (max-width: 1400px) {
  header .navigation li a {
    font-size: 12px;
  }
}
header .navigation li a:hover {
  color: #000;
  border-bottom: 1px solid #e4ecea;
}
header ul {
  display: inline-block;
}
header ul.login {
  list-style: none;
  float: right;
  position: relative;
  top: -2px;
}
@media (max-width: 992px) {
  header ul.login {
    margin-right: -60px;
    position: absolute;
    top: 32px;
    right: 0px;
  }
  header ul.login i {
    font-size: 23px;
  }
  header ul.login li {
    margin: 0 50px;
    position: relative;
  }
  header ul.login li:first-child::after {
    content: "";
    display: block;
    width: 1px;
    height: 82px;
    background-color: #eef3f3;
    position: absolute;
    top: -32px;
    left: -20px;
  }
  header ul.login li:first-child::before {
    content: "";
    display: block;
    width: 1px;
    height: 82px;
    background-color: #eef3f3;
    position: absolute;
    top: -32px;
    right: -20px;
  }
  header ul.login li:nth-child(2) i {
    font-size: 24px;
    position: absolute;
    top: 45%;
    right: 0;
    transform: translate(0, -50%);
    color: #717171;
    transition: ease all 0.3s;
  }
  header ul.login li:nth-child(2) i:hover {
    color: #222;
  }
}
header ul.login .test-results a {
  display: inline-block;
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 20px;
  background-color: #45C7ED;
  text-decoration: none;
  padding: 14px 26px 11px;
  transition: ease all 0.3s;
}
@media (max-width: 992px) {
  header ul.login .test-results a {
    display: none;
  }
}
@media (max-width: 1400px) {
  header ul.login .test-results a {
    font-size: 12px;
  }
}
@media (max-width: 1200px) {
  header ul.login .test-results a {
    padding: 10px 10px 6px;
  }
}
header ul.login .test-results a:hover {
  background-color: #5cceef;
}
header ul.login li {
  display: inline-block;
  position: relative;
}
header ul.login li:nth-child(2) {
  display: none;
}
@media (max-width: 992px) {
  header ul.login li:nth-child(2) {
    display: inline-block;
  }
}
header ul.login li:first-child {
  margin-right: 10px;
}
header ul.login li:first-child a {
  color: #717171;
  font-family: "Gilroy";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-transform: uppercase;
  transition: ease all 0.3s;
  text-decoration: none;
  padding-bottom: 4px;
  border-bottom: 1px solid transparent;
  margin-right: 22px;
}
@media (max-width: 1400px) {
  header ul.login li:first-child a {
    font-size: 12px;
  }
}
@media (max-width: 992px) {
  header ul.login li:first-child a:first-child {
    display: none;
  }
}
header ul.login li:first-child a i {
  font-size: 24px;
  position: absolute;
  top: 45%;
  right: 0%;
  transform: translate(0%, -50%);
}
header ul.login li:first-child a span {
  width: 19px;
  height: 19px;
  background-color: #D91638;
  border-radius: 100%;
  color: #fff;
  text-align: center;
  line-height: 19px;
  display: block;
  position: absolute;
  top: -10px;
  right: -7px;
  font-size: 12px;
  font-weight: 400;
  line-height: 21.97px;
  padding-left: 3px;
}
header ul.login li:first-child a:hover {
  color: #000;
  border-bottom: 1px solid #e4ecea;
}
header ul.login li ul.menu-dropdown {
  display: block;
  position: absolute;
  top: 20px;
  left: -140px;
  width: auto;
  border-radius: 0 10px 10px 10px;
  background-color: #eef3f3;
  margin: 0;
  padding: 10px;
  opacity: 0;
  visibility: hidden;
  transition: ease all 0.3s;
  z-index: 9999;
}
@media (max-width: 1400px) {
  header ul.login li ul.menu-dropdown {
    width: 104px;
    left: -120px;
  }
}
@media (max-width: 1200px) {
  header ul.login li ul.menu-dropdown {
    left: -105px;
  }
}
header ul.login li ul.menu-dropdown li:nth-child(2) {
  display: block;
}
header ul.login li ul.menu-dropdown a {
  font-size: 14px;
  font-weight: 400;
  text-transform: none;
  white-space: nowrap;
}
header ul.login li ul.menu-dropdown a:hover {
  border: 0px solid transparent;
  color: #D91638;
}
header ul.login li ul.menu-dropdown li:last-child a {
  padding: 0;
  background-color: transparent;
  color: #717171;
}
header ul.login li ul.menu-dropdown li:last-child a:hover {
  border: 0px solid transparent;
  color: #D91638;
}
@media (max-width: 1200px) {
  header ul.login .languages {
    display: none;
  }
}
header ul.login .languages .language-selector {
  height: 27px;
  margin-left: 6px;
}
header ul.login .languages a {
  color: #050708 !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  text-transform: uppercase;
  text-decoration: none;
}
header ul.login .languages .lang-toggle {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  color: #050708;
  text-transform: uppercase;
  height: 100%;
  width: 53px;
  z-index: 103;
}
header ul.login .languages .lang-toggle:after {
  content: url("../includes/src/img/icn-arrow-down.svg");
  margin-left: 6px;
  margin-top: -5px;
}
header ul.login .languages .lang-dropdown {
  display: none;
  position: absolute;
  transform: translate(2px, 0px) !important;
  background-color: #FFFFFF;
  list-style-type: none;
  padding-left: 0;
  z-index: 102;
  padding-top: 5px;
}
header ul.login .languages .lang-dropdown .lang-item {
  display: flex;
  margin-right: 0;
}
header ul.login .languages .lang-dropdown .lang-item a {
  color: #050708 !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  text-transform: uppercase;
  width: 53px;
  max-width: 53px;
  align-items: center;
  display: flex;
  padding: 8px;
  margin-right: unset;
}
header ul.login .languages .lang-dropdown .lang-item:hover a {
  color: #45C7ED !important;
  border-bottom: unset;
}

@media (max-width: 992px) {
  .container {
    width: 100%;
  }
}
.my-a {
  position: absolute;
  left: -140px;
  top: -8px;
  white-space: nowrap;
  font-weight: 600 !important;
  background-color: #eef3f3;
  padding: 8px;
  border-radius: 10px;
}
@media (max-width: 1400px) {
  .my-a {
    left: -120px;
  }
}
@media (max-width: 1200px) {
  .my-a {
    left: -105px;
    top: -8px;
  }
}

.all-warpper:hover ul.menu-dropdown {
  opacity: 1;
  visibility: visible;
}

#nav-icon3 {
  width: 35px;
  height: 20px;
  transform: rotate(0);
  transition: 0.5s ease-in-out;
  cursor: pointer;
  position: fixed;
  top: 30px;
  right: 30px;
  display: none;
  z-index: 9999999999;
  display: none;
}
@media (max-width: 992px) {
  #nav-icon3 {
    display: block;
  }
}
#nav-icon3 span {
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  background: #3f4c57;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  transform: rotate(0);
  transition: 0.25s ease-in-out;
}
#nav-icon3 span:nth-child(1) {
  top: 0;
}
#nav-icon3 span:nth-child(2), #nav-icon3 span:nth-child(3) {
  top: 10px;
}
#nav-icon3 span:nth-child(4) {
  top: 20px;
}
#nav-icon3.open span:nth-child(1), #nav-icon3.open span:nth-child(4) {
  top: 18px;
  width: 0%;
  left: 50%;
}
#nav-icon3.open span:nth-child(2) {
  transform: rotate(45deg);
}
#nav-icon3.open span:nth-child(3) {
  transform: rotate(-45deg);
}

.mob-nav {
  position: fixed;
  top: 0;
  right: 0;
  width: 300px;
  height: 100%;
  min-height: 100%;
  background-color: #fff;
  z-index: 9;
  padding-top: 100px;
  transition: ease all 0.4s;
  opacity: 0;
  visibility: hidden;
  display: none;
  box-shadow: -15px 0 12px 1px rgba(0, 0, 0, 0.04);
  overflow-y: auto;
}
.mob-nav.active {
  z-index: 99999;
}
@media (max-width: 992px) {
  .mob-nav {
    display: block;
  }
}
.mob-nav .lang-dropdown-mob {
  display: flex;
  position: absolute;
  top: 32px;
}
.mob-nav .lang-dropdown-mob .lang-item {
  height: 45px;
  width: 45px;
  border-bottom: unset;
  padding-bottom: 0;
}
.mob-nav .lang-dropdown-mob .lang-item .language {
  color: #050708 !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  text-transform: uppercase;
  text-decoration: none;
}
.mob-nav ul {
  list-style: none;
  padding-left: 0;
  margin: 0 20px;
  width: calc(100% - 40px);
}
.mob-nav ul li {
  text-align: left;
  margin-bottom: 15px;
  border-bottom: 1px solid #eef3f3;
  padding-bottom: 10px;
}
.mob-nav ul li a {
  color: #666767;
  text-decoration: none;
  transition: ease all 0.3s;
  position: relative;
  font-size: 14px;
  font-weight: 400;
  text-transform: uppercase;
}
.mob-nav.active {
  opacity: 1;
  visibility: visible;
}
.mob-nav .navigation-mobile {
  margin-top: 40px;
  list-style: none;
}
.mob-nav .navigation-mobile li {
  display: block;
  margin-bottom: 20px;
}
.mob-nav .navigation-mobile li a,
.mob-nav .navigation-mobile li a:visited {
  color: #bababa;
  font-family: "Poppins";
  font-size: 18px;
  font-weight: 600;
  text-decoration: none;
  transition: ease all 0.3s;
}
.mob-nav .contact-information {
  margin-top: 10px;
  background-color: #e6edec;
  padding: 20px;
}
.mob-nav .contact-information .phone a {
  font-weight: 400;
  font-size: 30px;
  color: #222222;
  text-decoration: none;
  display: inline-block;
  margin-bottom: 2px;
  margin-top: 4px;
}
.mob-nav .contact-information .email a {
  font-size: 14px;
  font-weight: 400;
  color: #94999a;
  line-height: 24.04px;
  text-decoration: none;
}
.mob-nav .form-input {
  padding: 20px;
  position: relative;
}
.mob-nav .form-input input {
  display: block;
  width: 100%;
  border: 1px solid #e4ecea;
  font-size: 14px;
  font-weight: 300;
  padding: 10px 10px;
  border-radius: 0px;
  height: 38px;
}
.mob-nav .form-input button {
  position: absolute;
  top: 22px;
  right: 30px;
  border-radius: 0;
  border: 0px;
  height: 38px;
  background-color: transparent;
}
.mob-nav .form-input button i {
  font-size: 18px;
}

.first-revel {
  background-color: #f5f6f7;
  height: calc(100vh - 108px - 31px - 90px);
  min-height: 500px;
  position: relative;
}
.first-revel .hero-wrapper {
  max-width: 574px;
  margin: 0 auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@media (max-width: 767px) {
  .first-revel .hero-wrapper {
    max-width: 100%;
    width: 100%;
    padding: 0 30px;
  }
}
.first-revel .hero-wrapper h1 {
  color: #D91638;
  line-height: 19.65px;
  font-size: 24px;
  font-weight: 700;
  text-transform: uppercase;
}
.first-revel .hero-wrapper p {
  color: #76787a;
  font-family: "Gilroy";
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
  margin-top: 15px;
  display: block;
}
.first-revel .search-results {
  width: 460px;
  margin: 0 auto;
  margin-top: 60px;
  position: relative;
  overflow: hidden;
}
@media (max-width: 767px) {
  .first-revel .search-results {
    width: 100%;
  }
}
.first-revel .search-results input {
  display: block;
  width: 100%;
}
.first-revel .search-results input[type=text] {
  color: #000000;
  font-family: "Gilroy";
  font-size: 14px;
  font-weight: 300;
  line-height: 19.65px;
  padding: 12px 15px;
  border: 1px solid #e4ecea;
  border-radius: 0px;
  transition: ease all 0.3s;
}
@media (max-width: 480px) {
  .first-revel .search-results input[type=text] {
    font-size: 12px;
  }
}
.first-revel .search-results input[type=text]:focus {
  border: 1px solid #D91638;
}
.first-revel .search-results input[type=submit] {
  position: absolute;
  top: 0;
  right: 0;
  width: auto;
  color: #fff;
  font-family: "Gilroy";
  font-size: 14px;
  font-weight: 400;
  text-transform: uppercase;
  background-color: #D91638;
  border: 0px;
  border-radius: 0px;
  padding: 18px 15px;
  cursor: pointer;
  transition: ease all 0.3s;
}
@media (max-width: 480px) {
  .first-revel .search-results input[type=submit] {
    font-size: 12px;
  }
}
.first-revel .search-results input[type=submit]:hover {
  background-color: #e82043;
}
@media (max-width: 480px) {
  .first-revel .search-results input[type=submit] {
    position: relative;
    display: block;
    width: 100%;
    border: 0px;
  }
}

.liner {
  display: block;
  height: 31px;
  background-color: #ffffff;
}

.home_404 h1,
.home_404 p {
  color: #D91638;
}
.home_404 a,
.home_404 a:visited,
.home_404 a:active,
.home_404 a:focus {
  text-decoration: none;
  color: #6d6f71;
  text-decoration: none;
  padding-bottom: 1px;
  border-bottom: 1px solid transparent;
  -webkit-transition: ease all 0.3s;
  transition: ease all 0.3s;
}
.home_404 a:hover,
.home_404 a:visited:hover,
.home_404 a:active:hover,
.home_404 a:focus:hover {
  color: #6d6f71;
  border-bottom: 1px solid #6d6f71;
}

.hero {
  padding: 20px;
  background-color: #f5f6f7;
}
.hero h2 {
  color: #D91638;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 20px;
}
@media (max-width: 1200px) {
  .hero h2 {
    font-size: 28px;
  }
}
@media (max-width: 992px) {
  .hero h2 {
    font-size: 24px;
  }
}
@media (max-width: 767px) {
  .hero h2 {
    text-align: center;
  }
}
.hero p {
  font-family: "Gilroy";
  font-size: 18px;
  font-weight: 400;
  color: #8f9193;
  line-height: 30px;
  margin-bottom: 30px;
}
@media (max-width: 767px) {
  .hero p {
    text-align: center;
  }
}
.hero img {
  width: 100%;
}
@media (max-width: 767px) {
  .hero .col-xs-12.col-sm-6 {
    text-align: center;
  }
}

.btn,
.btn:visited,
.btn:active,
.btn:focus {
  display: inline-block;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration: none;
  color: #fff;
  padding: 14px 30px 12px;
  background-color: #45C7ED;
  border-radius: 20px;
  transition: ease all 0.4s;
  transform: perspective(1px);
  backface-visibility: hidden;
}
.btn:hover,
.btn:visited:hover,
.btn:active:hover,
.btn:focus:hover {
  background-color: #5cceef;
  color: #fff;
  box-shadow: 0 8px 24px 0 rgba(68, 99, 137, 0.1);
  backface-visibility: hidden;
}

.heading-title {
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: #050708;
  text-align: center;
  margin-bottom: 10px;
}

.heading-text {
  color: #676766;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  max-width: 600px;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 30px;
}

.products-section {
  padding: 65px 0;
}
@media (max-width: 992px) {
  .products-section {
    padding: 40px;
  }
}

.p-r {
  position: relative;
}

.product-box {
  border: 1px solid transparent;
  transition: ease all 0.3s;
}
.product-box:hover {
  border: 1px solid #e4ecea;
  box-shadow: 0 8px 24px 0 rgba(68, 99, 137, 0.05);
}
.product-box .price_small {
  display: none;
}
.product-box a,
.product-box a:visited {
  display: block;
  width: 100%;
  text-decoration: none;
  padding: 15px;
}
.product-box img {
  width: 100%;
  display: block;
  height: 182px;
  object-fit: contain;
  margin-bottom: 26px;
}
.product-box .title {
  color: #313131;
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 10px;
}
.product-box .title h2 {
  font-size: 18px;
}
.product-box .price {
  margin-bottom: 10px;
}
.product-box .price span {
  font-size: 18px;
  font-weight: 600;
  line-height: 30px;
  color: #D91638;
}
.product-box p {
  font-size: 16px;
  font-weight: 300;
  line-height: 150%;
  color: #a1a2a4;
  margin-bottom: 15px;
}
.product-box span {
  color: #a1a2a4;
  font-weight: 400;
}

.text-block {
  padding: 80px 0;
  background-color: #f5f6f7;
}
@media (max-width: 767px) {
  .text-block {
    padding: 40px 0;
  }
}
.text-block h2 {
  color: #D91638;
  font-size: 24px;
  font-weight: 400;
  line-height: 19.65px;
  padding-bottom: 20px;
  margin-bottom: 10px;
}
@media (max-width: 767px) {
  .text-block h2 {
    text-align: center;
  }
}
.text-block h2::after {
  content: "";
  display: block;
  width: 64px;
  height: 1px;
  background-color: #5b7073;
  margin-top: 20px;
}
@media (max-width: 767px) {
  .text-block h2::after {
    margin: 20px auto 0;
  }
}
.text-block p {
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  color: #676766;
}
@media (max-width: 767px) {
  .text-block p {
    text-align: center;
  }
}
.text-block .row:first-child {
  margin-bottom: 40px;
}
@media (max-width: 767px) {
  .text-block .col-xs-12.col-sm-8 {
    text-align: center;
  }
}
.text-block a,
.text-block a:visited {
  font-size: 14px;
  font-weight: 600;
  color: #D91638;
  text-decoration: none;
  margin-top: 20px;
  display: inline-block;
  padding-bottom: 1px;
  border-bottom: 1px solid transparent;
  transition: ease all 0.3s;
}
.text-block a:hover,
.text-block a:visited:hover {
  color: #D91638;
  border-bottom: 1px solid #D91638;
}

.testimonials {
  padding: 70px 0;
  background-color: #fff;
}
@media (max-width: 992px) {
  .testimonials {
    padding: 40px;
  }
}
.testimonials h2 {
  margin-bottom: 50px;
}
.testimonials .lSNext::after {
  color: #4d4d4b !important;
}
.testimonials .lSPrev::after {
  color: #4d4d4b !important;
}
.testimonials .name {
  font-size: 18px;
  font-weight: 600;
  line-height: 27.97px;
  color: #D91638;
}
@media (max-width: 767px) {
  .testimonials .name {
    text-align: center;
  }
}
.testimonials .comment {
  font-size: 16px;
  font-weight: 300;
  line-height: 170%;
  margin-top: 15px;
  color: #a1a2a4;
}
@media (max-width: 767px) {
  .testimonials .comment {
    text-align: center;
  }
}
.testimonials .lSSlideOuter {
  padding: 0 1rem;
}

@media (max-width: 992px) {
  .lSAction > a.lSPrev {
    left: -30px;
  }
}
@media (max-width: 992px) {
  .lSAction > a.lSNext {
    right: -30px;
  }
}
.back-silver {
  background-color: #f5f6f7;
}

.breadcrumbs {
  padding: 5px 0;
}
.breadcrumbs .col-xs-12.col-sm-6 {
  padding-bottom: 0px;
}
@media (max-width: 767px) {
  .breadcrumbs .col-xs-12.col-sm-6.text-right {
    display: none;
  }
}
.breadcrumbs #breadcrumbs {
  padding: 0px;
}
.breadcrumbs ul {
  list-style: none;
}
.breadcrumbs ul li {
  display: inline-block;
  font-family: "Gilroy";
  font-size: 12px;
  font-weight: 300;
  color: #6d6f71;
}
.breadcrumbs ul li strong {
  font-weight: 300;
}
.breadcrumbs ul li a,
.breadcrumbs ul li a:visited {
  text-decoration: none;
  color: #6d6f71;
  padding-bottom: 1px;
  border-bottom: 1px solid transparent;
  transition: ease all 0.3s;
}
.breadcrumbs ul li a:hover,
.breadcrumbs ul li a:visited:hover {
  border-bottom: 1px solid #6d6f71;
}
.breadcrumbs ul li:not(:last-child) {
  margin-right: 6px;
}
.breadcrumbs .goBack,
.breadcrumbs .goBack:visited {
  font-family: "Gilroy";
  font-size: 12px;
  font-weight: 300;
  color: #6d6f71;
  text-decoration: none;
  padding-bottom: 1px;
  border-bottom: 1px solid transparent;
  transition: ease all 0.3s;
}
.breadcrumbs .goBack:hover,
.breadcrumbs .goBack:visited:hover {
  border-bottom: 1px solid #6d6f71;
}

@media (max-width: 992px) {
  .test-content .col-xs-12.col-sm-12.col-md-9 {
    padding-top: 5px;
  }
}
.test-content .your-data,
.test-content .test-search {
  padding: 30px 20px;
  background-color: #fff;
  margin-bottom: 20px;
}
.test-content .your-data h2,
.test-content .test-search h2 {
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #D91638;
  margin-bottom: 26px;
}
@media (max-width: 1200px) {
  .test-content .your-data h2,
  .test-content .test-search h2 {
    font-size: 18px;
  }
}
@media (max-width: 992px) {
  .test-content .test-search {
    margin-bottom: 0px;
  }
}
.test-content .test-content {
  padding: 40px 30px;
  background-color: #fff;
}
@media (max-width: 767px) {
  .test-content .test-content {
    padding: 40px 0 40px 20px;
  }
}
.test-content .test-content h1 {
  font-family: "Gilroy";
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #D91638;
}
.test-content .test-content span {
  font-family: "Gilroy";
  font-size: 16px;
  font-weight: 400;
  display: block;
  margin-bottom: 10px;
}
.test-content .test-content p {
  color: #6d6f71;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
}
.test-content .test-content p:not(:last-child) {
  margin-bottom: 20px;
}
.test-content .test-content .download {
  position: relative;
}
.test-content .test-content .download a {
  color: #D91638;
  font-size: 18px;
  font-weight: 600;
  line-height: 19.65px;
  text-decoration: none;
  transition: ease all 0.3s;
}
.test-content .test-content .download a i {
  font-size: 31px;
  position: relative;
  top: 8px;
}
.test-content .test-content .download a:hover {
  color: #eb3757;
}
.test-content .test-content .main-data table {
  font-family: "Gilroy";
  border-collapse: collapse;
  width: 100%;
  margin-top: 40px;
  border: 1px solid #ced9d8;
}
.test-content .test-content .main-data table td,
.test-content .test-content .main-data table th {
  padding: 16px;
}
.test-content .test-content .main-data table tr:nth-child(even) {
  background-color: #fff;
}
.test-content .test-content .main-data table th {
  text-align: left;
  background-color: #eef3f3;
  color: #878a8b;
  padding-top: 20px;
  border: 1px solid #ced9d8;
}
.test-content .test-content .main-data table th:not(:first-child) {
  text-align: center;
}
.test-content .test-content .main-data table td {
  color: #6d6f71;
  font-weight: 400;
  font-size: 16px;
  border-left: 1px solid #ced9d8;
}
.test-content .test-content .main-data table td:not(:first-child) {
  text-align: center;
}
.test-content .your-data ul {
  list-style: none;
}
.test-content .your-data ul li {
  font-family: "Gilroy";
  font-size: 14px;
  font-weight: 400;
  line-height: 19.65px;
  color: #6d6f71;
}
.test-content .your-data ul li:not(:last-child) {
  margin-bottom: 4px;
}
.test-content .test-search {
  position: relative;
}
.test-content .test-search .search-again {
  position: relative;
}
.test-content .test-search .search-again input {
  color: #000000;
  font-family: "Gilroy";
  font-size: 16px;
  font-weight: 300;
  line-height: 19.65px;
  padding: 12px 15px;
  border: 1px solid #e4ecea;
  border-radius: 0px;
  transition: ease all 0.3s;
  width: 100%;
}
@media (max-width: 1200px) {
  .test-content .test-search .search-again input {
    font-size: 12px;
  }
}
.test-content .test-search .search-again span {
  position: absolute;
  top: 0;
  right: 0;
  width: 49px;
  height: 45px;
  border-left: 1px solid #dae3e2;
  z-index: 9;
}
@media (max-width: 1200px) {
  .test-content .test-search .search-again span {
    width: 40px;
  }
}
.test-content .test-search .search-again span i {
  font-size: 25px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-55%, -40%);
  color: #5b7073;
}
@media (max-width: 1200px) {
  .test-content .test-search .search-again span i {
    font-size: 18px;
  }
}
.test-content .test-search a,
.test-content .test-search .active-search {
  font-family: "Gilroy";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 14px 30px 10px;
  display: inline-block;
  border-radius: 20px;
  background-color: #45C7ED;
  color: #fff;
  text-decoration: none;
  margin-top: 30px;
  transition: ease all 0.3s;
  border: 0px;
  cursor: pointer;
}
@media (max-width: 1200px) {
  .test-content .test-search a,
  .test-content .test-search .active-search {
    font-size: 12px;
    padding: 14px 18px 10px;
  }
}
.test-content .test-search a:hover,
.test-content .test-search .active-search:hover {
  background-color: #5cceef;
}

.back {
  margin-top: 20px;
}
.back a,
.back a:visited {
  font-family: "Gilroy";
  font-size: 16px;
  font-weight: 400;
  line-height: 19.65px;
  color: #D91638;
  text-decoration: none;
  transition: ease all 0.3s;
}
.back a:hover,
.back a:visited:hover {
  color: #e82043;
}

.main-data {
  overflow: auto;
}

.all-products {
  background-color: #f5f6f7;
  padding: 70px 0;
}
.all-products .product-box {
  background-color: #fff;
  height: 100%;
}
.all-products .product-box:hover {
  box-shadow: -2px 1px 15px 3px rgba(6, 8, 8, 0.06);
  border: 1px solid transparent;
}
.all-products h1 {
  margin-top: 0px;
}

.testimonials.back-silver {
  background-color: #f5f6f7;
}

.product-page-box .title h2 {
  font-weight: 400;
}
.product-page-box .button {
  display: none !important;
}
.product-page-box p {
  margin-top: 10px !important;
}

.test-content.content-inside {
  background-color: #f5f6f7;
  padding-bottom: 30px;
}
.test-content.content-inside h1 {
  margin-top: 0px;
  margin-bottom: 20px;
}

.breadcrumbs {
  background-color: #f5f6f7;
}

.contact-content .phone a,
.contact-content .phone a:visited {
  color: #222222;
  font-size: 28px;
  line-height: 19.69px;
  text-decoration: none;
  display: inline-block;
  margin-bottom: 10px;
  padding-bottom: 1px;
  border-bottom: 1px solid transparent;
  transition: ease all 0.3s;
}
.contact-content .phone a:hover,
.contact-content .phone a:visited:hover {
  border-bottom: 1px solid #222222;
}
.contact-content .email a,
.contact-content .email a:visited {
  color: #717171;
  font-size: 16px;
  font-weight: 400;
  line-height: 24.04px;
  text-decoration: none;
  padding-bottom: 1px;
  border-bottom: 1px solid transparent;
  transition: ease all 0.3s;
  margin-bottom: 20px;
  display: inline-block;
}
.contact-content .email a:hover,
.contact-content .email a:visited:hover {
  border-bottom: 1px solid #717171;
}
.contact-content .email a i,
.contact-content .email a:visited i {
  position: relative;
  top: 3px;
  font-size: 16px;
}
.contact-content p {
  color: #717171 !important;
}
.contact-content p a {
  color: #717171;
  font-size: 16px;
  font-weight: 400;
  text-decoration: none;
  padding-bottom: 1px;
  border-bottom: 1px solid transparent;
  transition: ease all 0.3s;
  display: inline-block;
}
.contact-content p a:hover {
  border-bottom: 1px solid #717171;
}

.test-content.content-inside.simple-page p {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
}
.test-content.content-inside.simple-page p:not(:last-child) {
  margin-bottom: 15px;
}
.test-content.content-inside.simple-page .c-row > div:not(:last-child) {
  margin-bottom: 30px;
}
.test-content.content-inside.simple-page h2,
.test-content.content-inside.simple-page h3,
.test-content.content-inside.simple-page h4,
.test-content.content-inside.simple-page h5,
.test-content.content-inside.simple-page h6 {
  font-weight: 400;
}

.c-row {
  margin-right: -15px;
  margin-left: -15px;
}

.test-search-page {
  padding-bottom: 150px !important;
}
.test-search-page h1 {
  font-size: 20px;
  font-weight: 400;
  line-height: 19.65px;
  color: #D91638;
}
.test-search-page .search-again,
.test-search-page .active-search {
  display: inline-block;
  margin-top: 0px !important;
}
.test-search-page .search-again {
  width: 60%;
  margin-right: 20px;
}
@media (max-width: 1400px) {
  .test-search-page .search-again {
    width: 50%;
  }
}
@media (max-width: 992px) {
  .test-search-page .search-again {
    width: 100%;
    margin-bottom: 20px;
  }
}

.google-map {
  width: 100%;
}
.google-map iframe {
  width: 100%;
  margin-bottom: 40px;
}

#cookie-notice {
  border-radius: 10px;
  min-width: unset !important;
  max-width: 1200px;
  width: calc(100% - 40px);
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 20px;
  left: calc(50% - 20px) !important;
  transform: translate(-50%, 0);
}
#cookie-notice .cookie-notice-container {
  border-radius: 10px;
  border: 1px solid #45C7ED;
  text-align: left;
  padding: 25px 30px 25px 30px;
}
@media (max-width: 1400px) {
  #cookie-notice .cookie-notice-container {
    flex-direction: column;
  }
}
@media (max-width: 1200px) {
  #cookie-notice .cookie-notice-container {
    padding: 20px;
  }
}
#cookie-notice .cookie-notice-container .cn-text-container {
  margin: 0;
  margin-right: 25px;
  margin-bottom: 25px;
}
#cookie-notice .cookie-notice-container .cn-text-container a {
  color: #45C7ED;
  text-decoration: underline;
}
#cookie-notice #cn-notice-buttons {
  display: flex;
}
#cookie-notice span {
  font-family: "Gilroy";
  font-size: 18px;
  font-weight: 600;
}
@media (max-width: 1200px) {
  #cookie-notice span {
    font-size: 16px;
  }
}
#cookie-notice .cn-button {
  border-radius: 35px;
  padding: 10px 20px;
  display: flex !important;
  justify-content: center;
  flex-direction: column;
  height: fit-content;
  margin-top: auto !important;
  margin-bottom: auto !important;
  font-family: "Gilroy";
  font-size: 18px;
  font-weight: 600;
  margin: 0 10px 0 0 !important;
}
#cookie-notice .cn-button:last-of-type {
  margin: 0 !important;
}
@media (max-width: 1200px) {
  #cookie-notice .cn-button {
    padding: 10px 14px;
  }
}
#cookie-notice #cn-accept-cookie {
  background-color: #45C7ED;
  color: #FFFFFF;
}
#cookie-notice #cn-accept-cookie:hover, #cookie-notice #cn-accept-cookie:active, #cookie-notice #cn-accept-cookie:focus, #cookie-notice #cn-accept-cookie:focus-visible {
  background-color: #5cceef;
  color: #fff;
  box-shadow: 0 8px 24px 0 rgba(68, 99, 137, 0.1);
  backface-visibility: hidden;
}
#cookie-notice #cn-refuse-cookie {
  background-color: #FFFFFF;
  color: #050708;
  border: 1px solid #050708;
}
#cookie-notice #cn-refuse-cookie:hover, #cookie-notice #cn-refuse-cookie:active, #cookie-notice #cn-refuse-cookie:focus, #cookie-notice #cn-refuse-cookie:focus-visible {
  background-color: #050708;
  color: #FFFFFF;
  box-shadow: 0 8px 24px 0 rgba(68, 99, 137, 0.1);
  backface-visibility: hidden;
}
#cookie-notice #cn-close-notice {
  background-size: 22px;
  background-repeat: no-repeat;
  top: 27px;
  right: 22px;
  margin: unset;
  width: 22px;
  height: 22px;
  opacity: 1;
}
@media (max-width: 1200px) {
  #cookie-notice #cn-close-notice {
    right: 15px;
  }
}
#cookie-notice #cn-close-notice::before, #cookie-notice #cn-close-notice::after {
  display: none;
}

.woocommerce-MyAccount-content label {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  color: #8f9092;
}
.woocommerce-MyAccount-content label span {
  margin-bottom: 0 !important;
}
.woocommerce-MyAccount-content input {
  margin-top: 0px !important;
}
.woocommerce-MyAccount-content .sfwc_my_account_tabrow {
  display: flex;
  padding-left: 0;
  list-style-type: none;
  margin: 0;
  margin-bottom: 50px;
}
.woocommerce-MyAccount-content .sfwc_my_account_tabrow::before {
  display: none;
}
.woocommerce-MyAccount-content .sfwc_my_account_tabrow .sfwc_my_account_tab {
  margin-right: 20px;
  border: unset;
  background: none;
  padding: 0;
}
.woocommerce-MyAccount-content .sfwc_my_account_tabrow .sfwc_my_account_tab a {
  backface-visibility: hidden;
  border: unset;
  border-radius: 20px;
  color: #fff !important;
  display: inline-block;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 14px 30px 12px;
  text-decoration: none;
  transform: perspective(1px);
  transition: all 0.4s ease;
  background-color: #45C7ED;
  color: #FFFFFF;
}
.woocommerce-MyAccount-content .sfwc_my_account_tabrow .sfwc_my_account_tab a:hover, .woocommerce-MyAccount-content .sfwc_my_account_tabrow .sfwc_my_account_tab a:active, .woocommerce-MyAccount-content .sfwc_my_account_tabrow .sfwc_my_account_tab a:focus, .woocommerce-MyAccount-content .sfwc_my_account_tabrow .sfwc_my_account_tab a:focus-visible {
  background-color: #5cceef;
  color: #fff;
  box-shadow: 0 8px 24px 0 rgba(68, 99, 137, 0.1);
  backface-visibility: hidden;
}
.woocommerce-MyAccount-content .sfwc_my_account_tabrow .sfwc_my_account_tab a:last-of-type {
  margin: 0 !important;
}
@media (max-width: 1200px) {
  .woocommerce-MyAccount-content .sfwc_my_account_tabrow .sfwc_my_account_tab a {
    padding: 10px 14px;
  }
}
.woocommerce-MyAccount-content input[type=button], .woocommerce-MyAccount-content input[type=submit], .woocommerce-MyAccount-content input[type=reset] {
  backface-visibility: hidden;
  border: unset;
  border-radius: 20px;
  color: #fff;
  display: inline-block;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 14px 30px 12px;
  text-decoration: none;
  transform: perspective(1px);
  transition: all 0.4s ease;
  background-color: #D91638;
  color: #FFFFFF;
}
.woocommerce-MyAccount-content input[type=button]:hover, .woocommerce-MyAccount-content input[type=button]:active, .woocommerce-MyAccount-content input[type=button]:focus, .woocommerce-MyAccount-content input[type=button]:focus-visible, .woocommerce-MyAccount-content input[type=submit]:hover, .woocommerce-MyAccount-content input[type=submit]:active, .woocommerce-MyAccount-content input[type=submit]:focus, .woocommerce-MyAccount-content input[type=submit]:focus-visible, .woocommerce-MyAccount-content input[type=reset]:hover, .woocommerce-MyAccount-content input[type=reset]:active, .woocommerce-MyAccount-content input[type=reset]:focus, .woocommerce-MyAccount-content input[type=reset]:focus-visible {
  background-color: #D91638;
  color: #fff;
  box-shadow: 0 8px 24px 0 rgba(68, 99, 137, 0.1);
  backface-visibility: hidden;
}
.woocommerce-MyAccount-content input[type=button]:last-of-type, .woocommerce-MyAccount-content input[type=submit]:last-of-type, .woocommerce-MyAccount-content input[type=reset]:last-of-type {
  margin: 0 !important;
}
@media (max-width: 1200px) {
  .woocommerce-MyAccount-content input[type=button], .woocommerce-MyAccount-content input[type=submit], .woocommerce-MyAccount-content input[type=reset] {
    padding: 10px 14px;
  }
}

.woocommerce-EditAccountForm fieldset {
  padding-top: 10px;
  margin-top: 30px;
}
.woocommerce-EditAccountForm fieldset input {
  width: 100%;
}
.woocommerce-EditAccountForm fieldset p {
  margin-bottom: 0;
}
.woocommerce-EditAccountForm span {
  margin-bottom: 0 !important;
}

table {
  width: 100% !important;
}

table tbody, table tfoot, table thead {
  border: 1px solid #050708;
}

tr th {
  padding: 20px;
  text-align: left;
  border: 1px solid #050708;
}
tr th span {
  font-weight: 600 !important;
  margin-bottom: 0 !important;
}

tr td {
  padding: 20px;
  text-align: left;
  border: 1px solid #050708;
}
tr td span {
  margin-bottom: 0 !important;
}

.order_details {
  margin-bottom: 30px;
}

mark {
  background-color: unset;
  font-weight: 600;
}

.woocommerce-error {
  color: #D91638;
}

.woocommerce-ResetPassword .woocommerce-Button {
  backface-visibility: hidden;
  border: unset;
  border-radius: 20px;
  color: #fff;
  display: inline-block;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 14px 30px 12px;
  text-decoration: none;
  transform: perspective(1px);
  transition: all 0.4s ease;
  background-color: #D91638;
  width: max-content;
  color: #FFFFFF;
}
.woocommerce-ResetPassword .woocommerce-Button:hover, .woocommerce-ResetPassword .woocommerce-Button:active, .woocommerce-ResetPassword .woocommerce-Button:focus, .woocommerce-ResetPassword .woocommerce-Button:focus-visible {
  background-color: #D91638;
  color: #fff;
  box-shadow: 0 8px 24px 0 rgba(68, 99, 137, 0.1);
  backface-visibility: hidden;
}
.woocommerce-ResetPassword .woocommerce-Button:last-of-type {
  margin: 0 !important;
}
@media (max-width: 1200px) {
  .woocommerce-ResetPassword .woocommerce-Button {
    padding: 10px 14px;
  }
}

#sfwc_form_add_subaccount_frontend .company {
  display: none !important;
}

#sfwc_form_add_subaccount_frontend p {
  display: inline-block !important;
}
#sfwc_form_add_subaccount_frontend p span:first-child {
  display: none;
}

.woocommerce-customer-details address {
  margin-top: 30px;
  min-height: 160px;
}

.products-section .lSSlideWrapper {
  min-height: 400px;
}

.selectize-control.single .selectize-input {
  background-image: unset;
}

.ui-accordion-icons span {
  margin-bottom: 0 !important;
}

.ui-accordion {
  margin-bottom: 30px;
}

#frontend_manage_subaccounts_edit_subaccount h2 {
  display: none;
}

#sfwc-user-switcher-pane .selectize-control.single .selectize-input {
  max-width: 100% !important;
}

.sub-menu {
  position: absolute;
  top: 50px;
  left: 0;
  width: 200px;
  text-align: left;
  padding: 10px;
  background-color: #FFFFFF;
}

.menu-item-has-children:after {
  content: url("../includes/src/img/icn-arrow-down.svg");
  margin-left: 6px;
  margin-top: -5px;
}

.cart_item td {
  padding: 9px 2px !important;
}

.clearfix {
  display: table;
  content: "";
  clear: both;
}

.testimonials.in-pages {
  background-color: #f5f6f7;
}

.summary.entry-summary {
  width: 100% !important;
  float: none !important;
  display: block;
}

.product-top {
  background-color: #f5f6f7;
  padding-bottom: 20px;
}
.product-top .product_meta {
  display: none;
}
.product-top h1.product_title.entry-title {
  font-size: 24px;
  font-weight: 600;
  text-transform: uppercase;
  color: #D91638;
  margin-bottom: 14px;
}
.product-top .subtitle {
  font-weight: 400;
  line-height: 150%;
  margin-bottom: 20px;
  font-size: 18px;
}
.product-top .woocommerce-product-details__short-description {
  margin-bottom: 30px;
}
.product-top .woocommerce-product-details__short-description p {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #676766;
  max-width: 525px;
}
.product-top .new_text {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #676766;
  margin-bottom: 6px;
}
.product-top .new_text b {
  color: #505151;
  font-weight: 400;
}
.product-top .price {
  margin: 30px 0;
  color: #676766;
}
.product-top .price .price_small {
  font-size: 14px;
  font-weight: 400;
  color: #676766;
  margin-right: 6px;
}
.product-top .price .woocommerce-Price-amount.amount {
  font-size: 24px;
  color: #222222;
}
.product-top .cart .single_add_to_cart_button {
  display: inline-block;
  text-transform: uppercase;
  text-decoration: none;
  color: #fff;
  padding: 15px 30px 12px;
  background-color: #D91638 !important;
  border-radius: 20px;
  position: relative;
  top: -2px;
  transition: ease all 0.4s;
  font-weight: 400;
}
.product-top .cart .single_add_to_cart_button:hover {
  background-color: #e82043 !important;
  box-shadow: 0 8px 24px 0 rgba(68, 99, 137, 0.1);
}

.woocommerce-notices-wrapper {
  margin: 0 15px;
}
.woocommerce-notices-wrapper .woocommerce-message {
  margin-bottom: 0px;
  border-top-color: #D91638;
}
.woocommerce-notices-wrapper .woocommerce-message::before {
  color: #D91638;
}

.woocommerce div.product form.cart .button,
.woocommerce div.product form.cart div.quantity {
  float: none;
}

.plus,
.quantity,
.minus,
.qty-u,
.qty-m {
  display: inline-block;
}

.woocommerce div.product form.cart div.quantity {
  margin: 0;
}

.quantity {
  position: relative;
  left: -6px;
}

.qty {
  appearance: textfield;
  width: 48px !important;
  height: 43px;
  border: 1px solid #b5c5c4;
  border-radius: 0 !important;
  position: relative;
  top: 1px;
  color: #000000;
  font-size: 18px;
  font-weight: 400;
  text-transform: uppercase;
  background-color: transparent;
}
.qty::-webkit-inner-spin-button, .qty::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.minus,
.qty-m {
  border: 1px solid #b5c5c4;
  border-radius: 10px 0 0 10px;
  background-color: transparent;
  width: 46px;
  height: 43px;
  cursor: pointer;
  transition: ease all 0.3s;
  position: relative;
  left: -97px;
  top: 16px;
}
.minus:hover,
.qty-m:hover {
  background-color: #D91638;
  border: 1px solid #D91638;
}
.minus:hover::after,
.qty-m:hover::after {
  color: #fff;
}
.minus::after,
.qty-m::after {
  content: "F";
  display: block;
  width: 20px;
  height: 20px;
  font-family: "dbs";
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  color: #D91638;
  position: absolute;
  top: 56%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 14px;
  transition: ease all 0.3s;
}
@media (max-width: 767px) {
  .minus,
  .qty-m {
    top: 17px;
  }
}

.quantity {
  width: 150px;
  text-align: center;
  left: 43px;
  height: 43px;
}

.plus,
.qty-u {
  border: 1px solid #b5c5c4;
  border-radius: 0 10px 10px 0;
  background-color: transparent;
  width: 46px;
  height: 43px;
  position: relative;
  position: relative;
  left: -51px;
  top: 16px;
  cursor: pointer;
  transition: ease all 0.3s;
}
.plus:hover,
.qty-u:hover {
  background-color: #D91638;
  border: 1px solid #D91638;
}
.plus:hover::after,
.qty-u:hover::after {
  color: #fff;
}
.plus::after,
.qty-u::after {
  content: "A";
  display: block;
  width: 20px;
  height: 20px;
  font-family: "dbs";
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  color: #D91638;
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 14px;
  transition: ease all 0.3s;
}
@media (max-width: 767px) {
  .plus,
  .qty-u {
    top: 17px;
  }
}

.shop_table .quantity {
  left: 40px;
  top: -14px;
}
.shop_table .quantity span {
  display: inline-block;
  top: 18px;
}
.shop_table .quantity span.qty-m {
  left: -97px;
}
.shop_table .quantity span.qty-u {
  left: -51px;
}

@media (max-width: 480px) {
  .woocommerce div.product form.cart .button {
    padding: 15px 16px 12px;
  }
}
.woocommerce-form-coupon-toggle {
  display: none;
}

.test-content.content-inside.simple-page.checkout-pages h1 {
  margin-bottom: 30px;
}
.test-content.content-inside.simple-page.checkout-pages .woocommerce-notices-wrapper {
  margin: 0;
  margin-bottom: 20px;
}
.test-content.content-inside.simple-page.checkout-pages table.shop_table {
  border: 0px;
}
.test-content.content-inside.simple-page.checkout-pages table.shop_table thead {
  background-color: #f6f9f9;
}
.test-content.content-inside.simple-page.checkout-pages table.shop_table thead th {
  color: #222;
  font-size: 16px;
  font-weight: 400;
}
.test-content.content-inside.simple-page.checkout-pages table.shop_table td.product-remove {
  width: 60px;
}
.test-content.content-inside.simple-page.checkout-pages table.shop_table td.product-thumbnail {
  width: 200px;
}
.test-content.content-inside.simple-page.checkout-pages table.shop_table td.product-thumbnail img {
  width: 161px;
  height: 100px;
  object-fit: contain;
}
.test-content.content-inside.simple-page.checkout-pages table.shop_table td.product-remove a {
  color: #fff !important;
  position: relative;
}
.test-content.content-inside.simple-page.checkout-pages table.shop_table td.product-remove a:hover {
  background-color: #fff !important;
}
.test-content.content-inside.simple-page.checkout-pages table.shop_table td.product-remove a:hover::after {
  color: #eb3757 !important;
}
.test-content.content-inside.simple-page.checkout-pages table.shop_table td.product-remove a::after {
  content: "D";
  display: inline-block;
  font-family: "dbs";
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  color: #D91638 !important;
  font-size: 14px;
  position: absolute;
  top: 0;
  left: 0;
  transition: ease all 0.3s;
}
.test-content.content-inside.simple-page.checkout-pages table.shop_table td.product-name a {
  color: #222;
  font-size: 16px;
  font-weight: 400;
  text-decoration: none;
}
.test-content.content-inside.simple-page.checkout-pages table.shop_table td.product-price .price_small,
.test-content.content-inside.simple-page.checkout-pages table.shop_table td.product-subtotal .price_small {
  display: none;
}
.test-content.content-inside.simple-page.checkout-pages table.shop_table td.product-price .woocommerce-Price-currencySymbol,
.test-content.content-inside.simple-page.checkout-pages table.shop_table td.product-subtotal .woocommerce-Price-currencySymbol {
  display: inline-block;
}
.test-content.content-inside.simple-page.checkout-pages table.shop_table td.product-price span,
.test-content.content-inside.simple-page.checkout-pages table.shop_table td.product-subtotal span {
  margin-bottom: 0px;
}
.test-content.content-inside.simple-page.checkout-pages table.shop_table tr, .test-content.content-inside.simple-page.checkout-pages table.shop_table th, .test-content.content-inside.simple-page.checkout-pages table.shop_table td {
  border: 0px;
}
.test-content.content-inside.simple-page.checkout-pages table.shop_table td.actions {
  border-top: 1px solid #e4ecea;
  padding-top: 20px;
  padding-left: 0px;
  padding-right: 0px;
}
.test-content.content-inside.simple-page.checkout-pages table.shop_table td.actions button[name=update_cart] {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 20px;
  color: #fff;
  background-color: #45C7ED;
  padding: 14px 30px 12px;
  transition: ease all 0.3s;
}
.test-content.content-inside.simple-page.checkout-pages table.shop_table td.actions button[name=update_cart]:hover {
  background-color: #5cceef;
  box-shadow: 0 8px 24px 0 rgba(68, 99, 137, 0.1);
}
.test-content.content-inside.simple-page.checkout-pages table.shop_table td.actions .coupon #coupon_code {
  font-size: 14px;
  font-weight: 300;
  width: 285px;
  height: 44px;
  border: 1px solid #dae3e2;
  border-radius: 0px;
  padding-left: 12px;
}
@media (max-width: 767px) {
  .test-content.content-inside.simple-page.checkout-pages table.shop_table td.actions .coupon #coupon_code {
    display: block;
    width: 100%;
    margin-bottom: 10px;
  }
}
.test-content.content-inside.simple-page.checkout-pages table.shop_table td.actions .coupon .button {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 14px 30px 12px;
  background-color: #dae3e2;
  border-radius: 20px;
  margin-left: 20px;
  transition: ease all 0.3s;
  cursor: pointer;
}
@media (max-width: 767px) {
  .test-content.content-inside.simple-page.checkout-pages table.shop_table td.actions .coupon .button {
    display: block;
    width: 100%;
  }
}
.test-content.content-inside.simple-page.checkout-pages table.shop_table td.actions .coupon .button:hover {
  background-color: #e9eeed;
  box-shadow: 0 8px 24px 0 rgba(68, 99, 137, 0.05);
}
.test-content.content-inside.simple-page.checkout-pages #customer_details,
.test-content.content-inside.simple-page.checkout-pages #order_review {
  display: inline-block;
  width: 48%;
  float: left;
}
@media (max-width: 767px) {
  .test-content.content-inside.simple-page.checkout-pages #customer_details,
  .test-content.content-inside.simple-page.checkout-pages #order_review {
    float: none;
    width: 100%;
    margin-right: 0;
  }
}
.test-content.content-inside.simple-page.checkout-pages #customer_details h3,
.test-content.content-inside.simple-page.checkout-pages #order_review h3 {
  font-size: 24px;
  font-weight: 400;
  color: #D91638;
  margin-bottom: 25px;
}
.test-content.content-inside.simple-page.checkout-pages #customer_details .woocommerce-additional-fields h3,
.test-content.content-inside.simple-page.checkout-pages #order_review .woocommerce-additional-fields h3 {
  display: none;
}
.test-content.content-inside.simple-page.checkout-pages #customer_details label,
.test-content.content-inside.simple-page.checkout-pages #order_review label {
  font-size: 16px;
  font-weight: 300;
}
.test-content.content-inside.simple-page.checkout-pages #customer_details {
  margin-right: 4%;
}
@media (max-width: 767px) {
  .test-content.content-inside.simple-page.checkout-pages #customer_details {
    margin-bottom: 30px;
  }
}
.test-content.content-inside.simple-page.checkout-pages span {
  margin-bottom: 0;
}
.test-content.content-inside.simple-page.checkout-pages #customer_details input {
  height: 40px;
  border: 1px solid #dae3e2;
  display: block;
  width: 100%;
  border-radius: 0px;
  padding-left: 10px;
  font-size: 16px;
  font-weight: 300;
}

form.checkout.woocommerce-checkout {
  margin-top: 50px;
}

.woocommerce form .form-row .required {
  color: #5a5a5a;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  line-height: 40px;
}

.select2-container .select2-selection--single {
  height: 40px;
  border-radius: 0px;
  border: 1px solid #dae3e2;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 40px;
}

.woocommerce-account-fields input {
  display: inline-block;
  width: auto !important;
  float: left;
}
.woocommerce-account-fields span {
  display: inline-block !important;
  position: relative;
  top: 5px;
  font-size: 14px !important;
  font-weight: 300 !important;
}

.test-content .test-content #ship-to-different-address {
  background-color: #f6f9f9;
  position: relative;
}
.test-content .test-content #ship-to-different-address span {
  display: inline-block;
  padding: 19px 19px 14px;
  font-size: 18px;
  font-weight: 400;
  color: #D91638;
}
.test-content .test-content #ship-to-different-address input {
  display: inline-block;
  width: auto !important;
  float: right;
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translate(0%, -50%);
}

#order_comments_field span {
  display: inline-block;
}
#order_comments_field span.woocommerce-input-wrapper {
  display: block;
}
#order_comments_field span.woocommerce-input-wrapper textarea {
  border: 1px solid #dae3e2;
  display: block;
  width: 100%;
  border-radius: 0px;
  padding-left: 10px;
  padding-top: 10px;
  height: 100px;
  font-size: 16px;
  font-weight: 300;
}

table.shop_table.woocommerce-checkout-review-order-table {
  border-collapse: collapse;
  border-right: 1px solid #e4ecea !important;
  border-bottom: 1px solid #e4ecea !important;
  border-left: 1px solid #e4ecea !important;
}
table.shop_table.woocommerce-checkout-review-order-table span {
  display: inline-block;
  font-size: 14px;
  font-weight: 300;
}
table.shop_table.woocommerce-checkout-review-order-table thead {
  background-color: #eef3f3 !important;
}
table.shop_table.woocommerce-checkout-review-order-table .cart-subtotal,
table.shop_table.woocommerce-checkout-review-order-table .order-total {
  background-color: #f6f9f9;
}
table.shop_table.woocommerce-checkout-review-order-table .cart-subtotal th,
table.shop_table.woocommerce-checkout-review-order-table .order-total th {
  font-size: 14px;
  font-weight: 400;
}
table.shop_table.woocommerce-checkout-review-order-table tbody td {
  padding: 15px 10px !important;
}
table.shop_table.woocommerce-checkout-review-order-table .shipping th {
  font-size: 14px;
  font-weight: 400;
}
table.shop_table.woocommerce-checkout-review-order-table .order-total td span {
  font-weight: 400;
}

.woocommerce-checkout #payment {
  border-radius: 0px;
  background-color: #f6f9f9;
}
.woocommerce-checkout #payment label {
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 20px !important;
}
.woocommerce-checkout #payment div.payment_box {
  background-color: #ebf0ef;
}
.woocommerce-checkout #payment div.payment_box::before {
  border: 1em solid #ebf0ef;
  border-right-color: transparent;
  border-left-color: transparent;
  border-top-color: transparent;
}
.woocommerce-checkout #payment div.payment_box p {
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
}
.woocommerce-checkout #payment ul.payment_methods {
  border-bottom: 0px;
  padding-bottom: 0px;
}

.woocommerce-privacy-policy-text a,
.woocommerce-privacy-policy-text a:visited {
  text-decoration: none;
  color: #6d6f71;
  transition: ease all 0.3s;
  padding-bottom: 1px;
  border-bottom: 1px solid transparent;
}
.woocommerce-privacy-policy-text a:hover,
.woocommerce-privacy-policy-text a:visited:hover {
  border-bottom: 1px solid #6d6f71;
}

.woocommerce-terms-and-conditions-wrapper > p {
  margin: 10px 0 !important;
  display: block;
  position: relative;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
}
.woocommerce-terms-and-conditions-wrapper > p span {
  display: inline-block !important;
}
.woocommerce-terms-and-conditions-wrapper > p a {
  text-decoration: none;
  color: #222;
  transition: ease all 0.3s;
  padding-bottom: 1px;
  border-bottom: 1px solid #222;
}
@media (max-width: 1400px) {
  .woocommerce-terms-and-conditions-wrapper > p {
    padding-left: 24px !important;
  }
  .woocommerce-terms-and-conditions-wrapper > p input {
    position: absolute;
    top: 8px;
    left: 0;
  }
  .woocommerce-terms-and-conditions-wrapper > p span {
    display: inline-block !important;
    float: left;
  }
}

.woocommerce #payment #place_order, .woocommerce-page #payment #place_order {
  float: left;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 20px;
  color: #fff;
  background-color: #D91638;
  padding: 14px 30px 12px;
  transition: ease all 0.3s;
  margin-top: 15px;
}
.woocommerce #payment #place_order:hover, .woocommerce-page #payment #place_order:hover {
  background-color: #D91638;
  box-shadow: 0 8px 24px 0 rgba(68, 99, 137, 0.1);
}

@media (max-width: 767px) {
  .test-content.content-inside.simple-page.checkout-pages .test-content {
    padding-right: 20px;
  }
}
.woocommerce-order-details span {
  display: inline-block !important;
}

.woocommerce-order-details__title {
  margin-bottom: 20px;
}

.woocommerce-order-overview span {
  display: inline-block !important;
}

@media (max-width: 767px) {
  .woocommerce ul.order_details li {
    display: block;
    width: 100%;
    float: none;
    margin-bottom: 20px;
  }
}

#customer_login {
  padding: 15px;
}
#customer_login .u-column1,
#customer_login .u-column2 {
  display: block;
  margin-bottom: 20px;
  border: 1px solid #eeeeee;
  padding: 20px;
}
#customer_login .u-column1 span,
#customer_login .u-column2 span {
  display: inline-block !important;
}
#customer_login .u-column1 input,
#customer_login .u-column2 input {
  display: block;
  padding: 10px;
  border-radius: 0px;
  margin-top: -10px;
  width: 100%;
  border-width: 1px;
}
#customer_login .u-column1 h2,
#customer_login .u-column2 h2 {
  margin-bottom: 15px;
}
#customer_login button {
  display: inline-block;
  padding: 12px 30px;
  border: 0px;
  border-radius: 20px;
  background-color: #D91638;
  color: #fff;
  cursor: pointer;
  transition: ease all 0.3s;
  width: max-content;
}
#customer_login button:hover {
  background-color: #e82043;
}
#customer_login .woocommerce-privacy-policy-text {
  margin-bottom: 15px;
}
#customer_login .lost_password a,
#customer_login .lost_password a:visited {
  color: #6d6f71;
}

.woocommerce-form__label.woocommerce-form__label-for-checkbox.woocommerce-form-login__rememberme {
  display: none;
}

.simple-page .test-content .woocommerce-MyAccount-navigation {
  display: none;
}

.account-menu {
  padding: 30px 20px;
  background-color: #fff;
  margin-bottom: 20px;
}
.account-menu h2 {
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #D91638;
  margin-bottom: 26px;
}
.account-menu ul {
  list-style: none;
}
.account-menu ul li {
  display: block;
}
.account-menu ul li:not(:last-child) {
  margin-bottom: 10px;
}
.account-menu ul li a,
.account-menu ul li a:visited {
  font-size: 16px;
  font-weight: 400;
  text-decoration: none;
  color: #8f9092;
}

.test-content.content-inside.simple-page.my-account-page .woocommerce {
  padding: 15px;
}
.test-content.content-inside.simple-page.my-account-page .woocommerce .woocommerce-form-row {
  display: flex;
  flex-direction: column;
}
.test-content.content-inside.simple-page.my-account-page .woocommerce p {
  color: #8f9092;
}
.test-content.content-inside.simple-page.my-account-page .woocommerce a,
.test-content.content-inside.simple-page.my-account-page .woocommerce a:visited {
  color: #8f9092;
}
.test-content.content-inside.simple-page.my-account-page .woocommerce span {
  display: inline-block;
}
.test-content.content-inside.simple-page.my-account-page .woocommerce input {
  padding: 10px;
  display: block;
}
.test-content.content-inside.simple-page.my-account-page .woocommerce em {
  margin-top: 20px;
  display: block;
}
.test-content.content-inside.simple-page.my-account-page .woocommerce fieldset input {
  margin-top: 5px;
}
.test-content.content-inside.simple-page.my-account-page .woocommerce .edit-account .woocommerce-Button {
  font-size: 16px;
  padding: 14px 30px 10px;
  display: inline-block;
  border-radius: 20px;
  background-color: #D91638;
  color: #fff;
  margin-top: 30px;
  transition: ease all 0.3s;
  border: 0;
  cursor: pointer;
}
.test-content.content-inside.simple-page.my-account-page .woocommerce .edit-account .woocommerce-Button:hover {
  background-color: #e82043;
}

.test-content .test-content .test-account-page {
  position: relative;
}
.test-content .test-content .test-account-page table {
  margin-top: 15px;
}
.test-content .test-content .test-account-page table th, .test-content .test-content .test-account-page table td {
  text-align: left !important;
  transition: ease all 0.3s;
}
.test-content .test-content .test-account-page table tr:hover td {
  background-color: rgba(220, 0, 0, 0.1);
}
.test-content .test-content .test-account-page table a {
  text-decoration: none;
  padding-bottom: 1px;
  border-bottom: 1px solid transparent;
  transition: ease all 0.3s;
}
.test-content .test-content .test-account-page table a:hover {
  border-bottom: 1px solid #8f9092;
}
.test-content .test-content .test-account-page .add-test {
  color: #fff !important;
  position: absolute;
  top: -68px;
  right: 0;
}

.middle-page.home_404 a {
  color: #fff !important;
  padding: 14px 30px 10px;
}

.woocommerce-MyAccount-navigation li.is-active a {
  color: rgb(220, 0, 0) !important;
}

footer {
  padding: 20px 0;
  background: linear-gradient(180deg, #43B7E2 0%, #4AC7EB 100%);
  height: 90px;
}
@media (max-width: 767px) {
  footer {
    height: auto;
    text-align: center;
  }
  footer .col-xs-12.col-sm-6.text-right {
    text-align: center;
  }
}
footer p {
  color: #FFFFFF;
  font-family: "Gilroy";
  font-size: 14px;
  font-weight: 300;
  line-height: 19.65px;
  transition: ease all 0.3s;
}
footer p a,
footer p a:visited {
  color: #FFFFFF;
  text-decoration: none;
  padding-bottom: 1px;
  border-bottom: 1px solid transparent;
  transition: ease all 0.3s;
}
footer p a:hover,
footer p a:visited:hover {
  color: #D91638;
  border-bottom: 1px solid #D91638;
}

.footer-menu {
  padding: 0 0 40px;
}
.footer-menu.no-main .row::after {
  display: none;
}
.footer-menu .row {
  padding-top: 50px;
}
.footer-menu .row::after {
  content: "";
  display: block;
  width: calc(100% - 2rem);
  position: absolute;
  top: 0;
  left: 1rem;
  height: 1px;
  background-color: #e4ecea;
}
@media (max-width: 992px) {
  .footer-menu .col-xs-12.col-sm-12 {
    text-align: center;
  }
}
.footer-menu .btn {
  position: absolute;
  bottom: 8px;
  right: 1rem;
}
@media (max-width: 992px) {
  .footer-menu .btn {
    position: relative;
    bottom: 0;
    right: auto;
  }
}
.footer-menu ul {
  list-style: none;
  margin-left: 0;
}
@media (max-width: 992px) {
  .footer-menu ul {
    display: none;
  }
}
.footer-menu ul li {
  display: inline-block;
}
.footer-menu ul li:not(:last-child) {
  margin-right: 26px;
}
.footer-menu ul li a {
  color: #050708;
  font-family: "Gilroy";
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  transition: ease all 0.3s;
  text-decoration: none;
  padding-bottom: 4px;
  border-bottom: 1px solid transparent;
}
.footer-menu ul li a:hover {
  color: #000;
  border-bottom: 1px solid #e4ecea;
}

.contact-popup,
.test-add-popup {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(250, 252, 252, 0.8);
  width: 100%;
  height: 100%;
  overflow: auto;
  opacity: 0;
  visibility: hidden;
  transition: ease all 0.3s;
  z-index: 99999999;
}
.contact-popup.active,
.test-add-popup.active {
  opacity: 1;
  visibility: visible;
}
.contact-popup .popupt-warpper,
.test-add-popup .popupt-warpper {
  width: 881px;
  box-shadow: -22px 10px 87px 11px rgba(6, 8, 8, 0.05);
  background-color: #ffffff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 37px;
}
@media (max-width: 992px) {
  .contact-popup .popupt-warpper,
  .test-add-popup .popupt-warpper {
    width: 100%;
    position: relative;
    top: auto;
    left: auto;
    transform: none;
    overflow: auto;
  }
}
.contact-popup .popupt-warpper .close-popup,
.contact-popup .popupt-warpper .close-popup-test,
.test-add-popup .popupt-warpper .close-popup,
.test-add-popup .popupt-warpper .close-popup-test {
  position: absolute;
  top: -15px;
  right: -15px;
  width: 30px;
  height: 30px;
  background-color: #15191a;
  border-radius: 100%;
  color: #fff;
  font-size: 32px;
  text-align: center;
  cursor: pointer;
  transition: ease all 0.3s;
}
@media (max-width: 992px) {
  .contact-popup .popupt-warpper .close-popup,
  .contact-popup .popupt-warpper .close-popup-test,
  .test-add-popup .popupt-warpper .close-popup,
  .test-add-popup .popupt-warpper .close-popup-test {
    top: 5px;
    right: 5px;
  }
}
.contact-popup .popupt-warpper .close-popup span,
.contact-popup .popupt-warpper .close-popup-test span,
.test-add-popup .popupt-warpper .close-popup span,
.test-add-popup .popupt-warpper .close-popup-test span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.contact-popup .popupt-warpper .close-popup:hover,
.contact-popup .popupt-warpper .close-popup-test:hover,
.test-add-popup .popupt-warpper .close-popup:hover,
.test-add-popup .popupt-warpper .close-popup-test:hover {
  background-color: #374244;
}
.contact-popup .popupt-warpper h2,
.test-add-popup .popupt-warpper h2 {
  font-size: 30px;
  font-weight: 400;
  margin-bottom: 15px;
  color: #D91638;
}
@media (max-width: 992px) {
  .contact-popup .popupt-warpper h2,
  .test-add-popup .popupt-warpper h2 {
    font-size: 24px;
  }
}
.contact-popup .popupt-warpper form,
.test-add-popup .popupt-warpper form {
  margin-left: -15px;
  margin-right: -15px;
}
.contact-popup .popupt-warpper form input,
.contact-popup .popupt-warpper form textarea,
.test-add-popup .popupt-warpper form input,
.test-add-popup .popupt-warpper form textarea {
  font-size: 16px;
  font-weight: 300;
  border: 0px;
  border-radius: 0;
  border-bottom: 1px solid rgba(206, 217, 216, 0.36);
  display: block;
  width: 100%;
  padding-left: 0;
}
.contact-popup .popupt-warpper form textarea,
.test-add-popup .popupt-warpper form textarea {
  padding: 10px;
  border: 1px solid rgba(206, 217, 216, 0.36);
  height: 120px;
}
.contact-popup .popupt-warpper form input[type=submit],
.test-add-popup .popupt-warpper form input[type=submit] {
  display: inline-block;
  width: auto;
  padding: 14px 40px 11px;
  color: #fff;
  font-size: 18px;
  font-weight: 400;
  text-transform: uppercase;
  border-radius: 21px;
  background-color: #D91638;
  cursor: pointer;
  transition: ease all 0.3s;
}
.contact-popup .popupt-warpper form input[type=submit]:hover,
.test-add-popup .popupt-warpper form input[type=submit]:hover {
  background-color: #e82043;
}
.contact-popup .popupt-warpper form .checkbox,
.test-add-popup .popupt-warpper form .checkbox {
  margin-top: 20px;
  position: relative;
}
.contact-popup .popupt-warpper form .checkbox input,
.test-add-popup .popupt-warpper form .checkbox input {
  position: absolute;
  top: -1px;
  left: 0;
  display: inline-block;
  width: auto;
  z-index: 9;
}
.contact-popup .popupt-warpper form .checkbox span,
.test-add-popup .popupt-warpper form .checkbox span {
  font-size: 12px;
  font-weight: 300;
  color: #9d9d9d;
  margin-left: 5px;
}
.contact-popup .popupt-warpper form .checkbox span.wpcf7-list-item-label,
.test-add-popup .popupt-warpper form .checkbox span.wpcf7-list-item-label {
  margin-left: 10px;
}
.contact-popup .popupt-warpper form .checkbox span a,
.test-add-popup .popupt-warpper form .checkbox span a {
  color: #9d9d9d;
  text-decoration: none;
  padding-bottom: 1px;
  border-bottom: 1px solid transparent;
  transition: ease all 0.3s;
}
.contact-popup .popupt-warpper form .checkbox span a:hover,
.test-add-popup .popupt-warpper form .checkbox span a:hover {
  border-bottom: 1px solid #9d9d9d;
}

#submit-add-test .error {
  border-bottom: 1px solid #D91638;
}

.lSSlideOuter .lSPager.lSpg > li {
  padding: 2px;
}
.lSSlideOuter .lSPager.lSpg > li:hover a {
  background-color: #D91638;
}
.lSSlideOuter .lSPager.lSpg > li a {
  width: 15px;
  height: 15px;
  background-color: rgba(255, 0, 0, 0.26);
}
.lSSlideOuter .lSPager.lSpg > li a:hover {
  background-color: #D91638 !important;
}
.lSSlideOuter .lSPager.lSpg > li.active a {
  background-color: #D91638;
}

.lSSlideOuter .lSPager.lSpg {
  margin-top: 20px !important;
  margin-bottom: 15px;
}

.lSAction {
  z-index: 9999;
}
.lSAction > a.lSNext {
  background: none;
  right: -40px;
}
.lSAction > a.lSNext::after {
  content: "H";
  display: block;
  display: inline-block;
  font-family: "dbs";
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  transform: rotate(180deg);
  font-size: 22px;
  color: #D91638;
}
.lSAction > a.lSPrev {
  background: none;
  left: -40px;
}
.lSAction > a.lSPrev::after {
  content: "H";
  display: block;
  display: inline-block;
  font-family: "dbs";
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  font-size: 22px;
  color: #D91638;
}

.acf-postbox {
  font-family: "Nunito", sans-serif;
  border: 1px solid #eee !important;
  border-radius: 5px !important;
  overflow: hidden;
  box-shadow: none;
}
.acf-postbox h2.ui-sortable-handle {
  background-color: #22A7F0;
  color: #fff;
  padding: 15px !important;
  font-size: 16px !important;
  text-transform: uppercase;
  border-bottom: 1px solid #22A7F0;
}
.acf-postbox h2.ui-sortable-handle span {
  color: #fff;
}
.acf-postbox h2.ui-sortable-handle .acf-hndle-cog {
  color: #fff;
}
.acf-postbox .handlediv {
  width: 46px;
  height: 53px;
}
.acf-postbox .handlediv .toggle-indicator {
  color: #fff;
}
.acf-postbox .acf-fields.-sidebar.-left::before {
  border: 0 !important;
  background-color: #2C3E50;
}
.acf-postbox .acf-fields .acf-tab-wrap.-left::before {
  border: 0px;
}
.acf-postbox .acf-fields .acf-tab-wrap.-left .acf-tab-group li .acf-tab-button {
  padding: 14px;
  background-color: #2C3E50;
  color: #fff;
  border: 0;
}
.acf-postbox .acf-fields .acf-tab-wrap.-left .acf-tab-group li.active a {
  color: #2C3E50;
  background-color: #fff !important;
}
.acf-postbox .acf-field::before {
  background-color: #fff !important;
}
.acf-postbox .acf-field input[type=text],
.acf-postbox .acf-field input[type=password],
.acf-postbox .acf-field input[type=number],
.acf-postbox .acf-field input[type=search],
.acf-postbox .acf-field input[type=email],
.acf-postbox .acf-field input[type=url],
.acf-postbox .acf-field textarea,
.acf-postbox .acf-field select {
  padding: 7px 10px;
  box-shadow: none;
  border-radius: 4px;
  font-size: 12px;
  color: #2C3E50;
  height: auto !important;
}
.acf-postbox .acf-input-wrap input,
.acf-postbox .acf-input-wrap select {
  height: auto !important;
}
.acf-postbox .acf-repeater.-table .acf-table {
  border-radius: 5px;
  overflow: hidden;
}
.acf-postbox .acf-repeater.-table .acf-table thead th {
  background-color: #2C3E50;
  color: #fff;
  border: 0;
  padding: 14px;
}
.acf-postbox .acf-repeater.-table .acf-table tr.acf-row .acf-row-handle span {
  font-size: 18px;
  color: #2C3E50;
}
.acf-postbox .acf-repeater.-table .acf-button.button.button-primary {
  background-color: #2C3E50;
  color: #fff;
  box-shadow: none;
  text-shadow: none;
}