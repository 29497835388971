.test-content.content-inside {
    background-color: #f5f6f7;   
    padding-bottom: 30px;

    h1 {
        margin-top: 0px;
        margin-bottom: 20px;
    }
}

.breadcrumbs {
    background-color: #f5f6f7;  
}

.contact-content {
    .phone {
        a,
        a:visited {
            color: #222222;
            font-size: 28px;
            line-height: 19.69px;
            text-decoration: none;
            display: inline-block;
            margin-bottom: 10px;
            padding-bottom: 1px;
            border-bottom: 1px solid transparent;

            transition: ease all .3s;

            &:hover {
                border-bottom: 1px solid #222222;
            }
        }
    }

    .email {
        a,
        a:visited {
            color: #717171;
            font-size: 16px;
            font-weight: 400;   
            line-height: 24.04px;
            text-decoration: none;
            padding-bottom: 1px;
            border-bottom: 1px solid transparent;
            transition: ease all .3s;
            margin-bottom: 20px;
            display: inline-block;

            &:hover {
                border-bottom: 1px solid #717171;
            }

            i {
                position: relative;
                top: 3px;
                font-size: 16px;
            }
        }
    }

    p {
        color: #717171 !important;

        a {
            color: #717171;
            font-size: 16px;
            font-weight: 400;   
            text-decoration: none;
            padding-bottom: 1px;
            border-bottom: 1px solid transparent;
            transition: ease all .3s;
            display: inline-block;

            &:hover {
                border-bottom: 1px solid #717171;
            }
        }
    }
}

.test-content.content-inside.simple-page {
    p {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 30px;
        &:not(:last-child) {
            margin-bottom: 15px;
        }
    }

    .c-row > div {
        &:not(:last-child) {
            margin-bottom: 30px;
        }
    }

    h2,
    h3,
    h4,
    h5,
    h6 {
        font-weight: 400;
    }
}

.c-row {
    margin-right: -15px;
    margin-left: -15px;
}

.test-search-page {
    padding-bottom: 150px !important;

    h1 {
        font-size: 20px;
        font-weight: 400;
        line-height: 19.65px;
        color: $red;
    }

    .search-again,
    .active-search {
        display: inline-block;
        margin-top: 0px !important;
    }

    .search-again {
        width: 60%;
        margin-right: 20px;

        @include responsive('xlg') {
            width: 50%;
        }

        @include responsive('sm') {
            width: 100%;
            margin-bottom: 20px;
        }
    }
}
.google-map {
    width: 100%;
    iframe {
        width: 100%;
        margin-bottom: 40px;
    }
}

// Cookie juosta
#cookie-notice {
    border-radius: 10px;
    min-width: unset !important;
    max-width: 1200px;
    width: calc(100% - 40px);
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 20px;
    left: calc(50% - 20px) !important;
    transform: translate(-50%, 0);
    .cookie-notice-container {
      border-radius: 10px;
      border: 1px solid $blue;
      text-align: left;
      padding: 25px 30px 25px 30px;
      @include responsive('xlg') {
        flex-direction: column;
      }
    @include responsive('md') {
        padding: 20px;
    }
      .cn-text-container {
        margin: 0;
        margin-right: 25px;
        margin-bottom: 25px;
        a {
          color: $blue;
          text-decoration: underline;
        }
      }
    }
    #cn-notice-buttons {
      display: flex;
    }
    span {
        font-family: 'Gilroy';
        font-size: 18px;
        font-weight: 600;
        @include responsive('md') {
            font-size: 16px;
        }
    }
    .cn-button {
        border-radius: 35px;
        padding: 10px 20px;
        display: flex !important;
        justify-content: center;
        flex-direction: column;
        height: fit-content;
        margin-top: auto !important;
        margin-bottom: auto !important;

        font-family: 'Gilroy';
        font-size: 18px;
        font-weight: 600;
        margin: 0 10px 0 0 !important;
        &:last-of-type {
            margin: 0 !important;
        }
        @include responsive('md') {
            padding: 10px 14px;
        }
    }
    #cn-accept-cookie {
        background-color: $blue;
        color: $white;
        &:hover, &:active, &:focus, &:focus-visible {
            background-color: #5cceef;
            color: #fff;
            box-shadow: 0 8px 24px 0 rgba(68, 99, 137, 0.1);
            backface-visibility: hidden;
        } 
    }
    #cn-refuse-cookie {
        background-color: $white;
        color: $black;
        border: 1px solid $black;
        &:hover, &:active, &:focus, &:focus-visible {
            background-color: $black;
            color: $white;
            box-shadow: 0 8px 24px 0 rgba(68, 99, 137, 0.1);
            backface-visibility: hidden;
        }
    }
    #cn-close-notice {
        // background-image: url("../assets/img/icn-x.svg");
        background-size: 22px;
        background-repeat: no-repeat;
        top: 27px;
        right: 22px;
        margin: unset;
        width: 22px;
        height: 22px;
        opacity: 1;
        @include responsive('md') {
            right: 15px;
        }
        &::before, &::after {
            display: none;
        }
    }
}

.woocommerce-MyAccount-content {
    label {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 30px;
        color: #8f9092; 
        span {
            margin-bottom: 0 !important;
        }
    }
    input {
        margin-top: 0px !important;
    }
    .sfwc_my_account_tabrow {
        display: flex;
        padding-left: 0;
        list-style-type: none;
        margin: 0;
        margin-bottom: 50px;
        &::before {
            display: none;
        }
        .sfwc_my_account_tab {
            margin-right: 20px;
            border: unset;
            background: none;
            padding: 0;
            a {
                backface-visibility: hidden;
                border: unset;
                border-radius: 20px;
                color: #fff !important;
                display: inline-block;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                padding: 14px 30px 12px;
                text-decoration: none;
                transform: perspective(1px);
                transition: all .4s ease;
                background-color: $blue;
                color: $white;
                &:hover, &:active, &:focus, &:focus-visible {
                    background-color: #5cceef;
                    color: #fff;
                    box-shadow: 0 8px 24px 0 rgba(68, 99, 137, 0.1);
                    backface-visibility: hidden;
                } 
                &:last-of-type {
                    margin: 0 !important;
                }
                @include responsive('md') {
                    padding: 10px 14px;
                }
            }
        }
    }
    input[type=button], input[type=submit], input[type=reset] {
        backface-visibility: hidden;
        border: unset;
        border-radius: 20px;
        color: #fff;
        display: inline-block;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        padding: 14px 30px 12px;
        text-decoration: none;
        transform: perspective(1px);
        transition: all .4s ease;
        background-color: $red;
        color: $white;
        &:hover, &:active, &:focus, &:focus-visible {
            background-color: $red;
            color: #fff;
            box-shadow: 0 8px 24px 0 rgba(68, 99, 137, 0.1);
            backface-visibility: hidden;
        } 
        &:last-of-type {
            margin: 0 !important;
        }
        @include responsive('md') {
            padding: 10px 14px;
        }
      }
}
.woocommerce-EditAccountForm {
    fieldset {
        padding-top: 10px;
        margin-top: 30px;
        input {
            width: 100%;
        }
        p {
            margin-bottom: 0;
        }
    }
    span {
        margin-bottom: 0 !important;
    }
}

table {
    width: 100% !important;
  }
  table tbody, table tfoot, table thead {
    border: 1px solid $black;
  }
  tr th {
    padding: 20px;
    text-align: left;
    border: 1px solid $black;
    span {
        font-weight: 600 !important;
        margin-bottom: 0 !important;
    }
  }
  tr td {
    padding: 20px;
    text-align: left;
    border: 1px solid $black;
    span {
        margin-bottom: 0 !important;
    }
  }

.order_details {
    margin-bottom: 30px;
}

mark {
    background-color: unset;
    font-weight: 600;
}

.woocommerce-error {
    // list-style-type: none;
    // padding-left: 0;
    color: $red;
}
.woocommerce-ResetPassword {
    .woocommerce-Button {
        backface-visibility: hidden;
        border: unset;
        border-radius: 20px;
        color: #fff;
        display: inline-block;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        padding: 14px 30px 12px;
        text-decoration: none;
        transform: perspective(1px);
        transition: all .4s ease;
        background-color: $red;
        width: max-content;
        color: $white;
        &:hover, &:active, &:focus, &:focus-visible {
            background-color: $red;
            color: #fff;
            box-shadow: 0 8px 24px 0 rgba(68, 99, 137, 0.1);
            backface-visibility: hidden;
        } 
        &:last-of-type {
            margin: 0 !important;
        }
        @include responsive('md') {
            padding: 10px 14px;
        }
    }
}

#sfwc_form_add_subaccount_frontend .company {
    display: none !important;
}

#sfwc_form_add_subaccount_frontend {
    p {
        display: inline-block !important;
        span:first-child {
            display: none;
        }
    }
}

.woocommerce-customer-details {
    address {
        margin-top: 30px;
        min-height: 160px;
    }
}
.products-section {
    .lSSlideWrapper {
        min-height: 400px;
    }
}

.selectize-control.single .selectize-input {
    background-image: unset;
}

.ui-accordion-icons {
    span {
        margin-bottom: 0 !important;
    }
}

.ui-accordion {
    margin-bottom: 30px;
}

#frontend_manage_subaccounts_edit_subaccount {
    h2 {
        display: none;
    }
}
#sfwc-user-switcher-pane .selectize-control.single .selectize-input {
    max-width: 100% !important;
}

.sub-menu {
    position: absolute;
    top: 50px;
    left: 0;
    width: 200px;
    text-align: left;
    padding: 10px;
    background-color: $white;
}
.menu-item-has-children {
    &:after {
        content: url("../includes/src/img/icn-arrow-down.svg");
        margin-left: 6px;
        margin-top: -5px;
    }
}

.cart_item {
    td {
        padding: 9px 2px !important;
    }
}